import React from "react";
import { Form, Field } from "formik";
import { Button } from "antd";
import { AntInput, AntInputTextArea } from "../CreateAntFields";

const AddFeedbackContainer = ({ handleSubmit, submitCount, isSubmitting }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        label="Name"
        component={AntInput}
        name="name"
        type="text"
        submitCount={submitCount}
        size="large"
        hasFeedback
      />
      <Field
        label="Phone"
        component={AntInput}
        name="phone"
        type="text"
        autoComplete="off"
        size="large"
        submitCount={submitCount}
        hasFeedback
      />
      <Field
        label="Suggestion"
        component={AntInputTextArea}
        name="suggestion"
        type="text"
        autoComplete="off"
        size="large"
        rows={6}
        submitCount={submitCount}
        hasFeedback
      />
      <Button
        type="primary"
        htmlType="submit"
        className="login-form-button"
        loading={isSubmitting}
      >
        Submit
      </Button>
    </Form>
  );
};

export default AddFeedbackContainer;
