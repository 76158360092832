import axios from "axios";
import { BASE_URL_ADMIN, BASE_URL_AUTH, BASE_URL_USER, BASE_URL_OAUTH } from "../constant";

export const admin = axios.create({
  baseURL: BASE_URL_ADMIN
});

export const user = axios.create({
  baseURL: BASE_URL_USER
});

export const auth = axios.create({
  baseURL: BASE_URL_AUTH
});

export const oauth = axios.create({
  baseURL: BASE_URL_OAUTH
})
