import React from 'react';
import Helmet from 'react-helmet';

const SEO = ({ title, thumbnail }) => {
  return (
    <Helmet>
      <title>
        {title !== 'Home'
          ? `${title} | Shumi Shop`
          : 'Shumi Shop - Proud of Your Hobby'}
      </title>
      <meta
        name='title'
        content={
          title !== 'Home'
            ? `${title} | Shumi Shop`
            : 'Shumi Shop - Proud of Your Hobby'
        }
      />
      <meta
        name='description'
        content={`${title} | Hi Prouders - Satu Platform untuk memenuhi kebutuhan hobimu dimana saja dan kapan saja di Shumi Hobby Shop Indonesia`}
      />

      <meta property='og:type' content='website' />
      {/* <meta property="og:url" content="https://metatags.io/"/> */}
      <meta
        property='og:title'
        content={
          title !== 'Home'
            ? `${title} | Shumi Shop`
            : 'Shumi Shop - Proud of Your Hobby'
        }
      />
      <meta
        property='og:description'
        content={`${title} | Hi Prouders - Satu Platform untuk memenuhi kebutuhan hobimu dimana saja dan kapan saja di Shumi Hobby Shop Indonesia`}
      />
      {thumbnail && <meta property='og:image' content={thumbnail} />}

      <meta property='twitter:card' content='summary_large_image' />
      {/* <meta property="twitter:url" content="https://metatags.io/"> */}
      <meta
        property='twitter:title'
        content={
          title !== 'Home'
            ? `${title} | Shumi Shop`
            : 'Shumi Shop - Proud of Your Hobby'
        }
      />
      <meta
        property='twitter:description'
        content={`${title} | Hi Prouders - Satu Platform untuk memenuhi kebutuhan hobimu dimana saja dan kapan saja di Shumi Hobby Shop Indonesia`}
      />
      <meta property='twitter:image' content={thumbnail && thumbnail} />
    </Helmet>
  );
};

export default SEO;
