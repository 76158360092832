import React from "react";
import { Link } from "react-router-dom";
import "./style.less";

export default ({ title, seeMore, children, extra }) => (
  <div className="container-title">
    <div className="container-title-content">
      <h2 className="heading-2">{title}</h2>
      {extra}
    </div>
    {seeMore && (
      <Link to="/products">
        <span className="heading-more">See More</span>
      </Link>
    )}
    {children && <div className="heading-more">{children}</div>}
  </div>
);
