import { admin, user as auth, user } from '../api/shumi';
import {
  FAILED_LOGIN,
  GET_LOGIN,
  GET_REGISTER,
  GET_LOGOUT,
  RESET_FAILED_LOGIN,
  SUCCESS_LOGIN,
  FAILED_LOGIN_TOKEN,
  SUCCESS_VERIFY,
  FAILED_VERIFY,
  GET_RESET_TOKEN,
  FAILED_GET_RESET_TOKEN,
  CREATE_RESET_TOKEN,
  GET_RESET_PASSWORD,
  SUCCESS_UPDATE_GENERAL,
  FAILED_UPDATE_PASSWORD,
  FAILED_UPDATE_EMAIL,
  SUCCESS_UPDATE_EMAIL,
  SUCCESS_VERIFY_UPDATE,
  FAILED_VERIFY_UPDATE,
} from '../constant';

export const getLogin = (payload) => async (dispatch) => {
  try {
    dispatch({ type: RESET_FAILED_LOGIN });
    const response = await auth.post('/login', payload);
    localStorage.setItem('token', response.data.data.token);
    dispatch({ type: GET_LOGIN, payload: response.data.data });
  } catch (e) {
    dispatch({
      type: FAILED_LOGIN,
      payload: e.response.data.data.errors,
    });
  }
};

export const getRegister = (payload) => async (dispatch) => {
  try {
    dispatch({ type: RESET_FAILED_LOGIN });
    const response = await auth.post('/register', payload);
    // localStorage.setItem("token", response.data.data.token);
    dispatch({
      type: GET_REGISTER,
      payload: response.data.data,
      justRegister: true,
    });
  } catch (e) {
    dispatch({
      type: FAILED_LOGIN,
      payload: e.response.data.data.errors,
    });
  }
};

export const getVerification = (payload) => async (dispatch) => {
  try {
    await auth.get(`/verify/${payload}`);
    dispatch({
      type: SUCCESS_VERIFY,
    });
  } catch (error) {
    dispatch({
      type: FAILED_VERIFY,
      payload: error.response.data.message,
    });
  }
};

export const createResetToken = (payload) => async (dispatch) => {
  try {
    await auth.post('/password/create', payload);
    dispatch({
      type: CREATE_RESET_TOKEN,
    });
  } catch (error) {
    dispatch({
      type: FAILED_GET_RESET_TOKEN,
      payload: error.response.data.message,
    });
  }
};

export const getResetToken = (token) => async (dispatch) => {
  try {
    const response = await auth.get(`/password/find/${token}`);
    dispatch({
      type: GET_RESET_TOKEN,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: FAILED_GET_RESET_TOKEN,
      payload: error.response.data.message,
    });
  }
};

export const resetPassword = (payload) => async (dispatch) => {
  try {
    await auth.post('/password/reset', payload);
    dispatch({
      type: GET_RESET_PASSWORD,
    });
  } catch (e) {
    dispatch({
      type: FAILED_GET_RESET_TOKEN,
      payload: e.response.data.message,
    });
  }
};

export const checkLoggedIn = () => async (dispatch) => {
  if (localStorage.getItem('token') !== null) {
    try {
      const response = await auth.get('/user', {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch({ type: SUCCESS_LOGIN, payload: response.data });
    } catch (e) {
      dispatch({ type: FAILED_LOGIN_TOKEN });
    }
  } else {
    dispatch({ type: FAILED_LOGIN_TOKEN });
  }
};

export const getOauthLogin = (payload) => async (dispatch) => {
  try {
    dispatch({ type: RESET_FAILED_LOGIN });
    const response = await auth.post('/login', payload);
    localStorage.setItem('token', response.data.data.token);
    dispatch({ type: GET_LOGIN, payload: response.data.data });
  } catch (e) {
    dispatch({
      type: FAILED_LOGIN,
      payload: e.response.data.data.errors,
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem('token');
  dispatch({ type: GET_LOGOUT });
};

export const submitSuggestion = (payload) => async () => {
  await auth.post('/suggestion', payload);
};

export const updateGeneralProfile = (payload) => async (dispatch) => {
  const body = new FormData();
  body.append('name', payload.name);
  body.append('phone', payload.phone);
  body.append('gender', payload.gender);
  await auth.post('/user/update', payload);
  dispatch({ type: SUCCESS_UPDATE_GENERAL, payload: payload });
};

export const updatePasswordProfile = (payload) => async (dispatch) => {
  try {
    await auth.post('/user/update_password', payload);
    return true;
  } catch (error) {
    dispatch({
      type: FAILED_UPDATE_PASSWORD,
      payload: error.response.data.data.errors,
    });
    return false;
  }
};

export const updateEmailProfile = (payload) => async (dispatch) => {
  try {
    await auth.post('/user/update_email', payload);
    dispatch({ type: SUCCESS_UPDATE_EMAIL, payload: payload.email });
    return true;
  } catch (error) {
    dispatch({
      type: FAILED_UPDATE_EMAIL,
      payload: error.response.data.data.errors,
    });
    return false;
  }
};

export const verifyUpdateEmail = (payload) => async (dispatch) => {
  try {
    await auth.post(`/user/update_email_confirmation`, { token: payload });
    dispatch({
      type: SUCCESS_VERIFY_UPDATE,
    });
  } catch (error) {
    dispatch({
      type: FAILED_VERIFY_UPDATE,
      payload: error.response.data.data.errors,
    });
  }
};
