import React from "react";
import NumberFormat from "react-number-format";

import "./style.less";

const CartCard = ({ name, price, qty, thumbnail }) => {
  return (
    <div className="cart-card">
      <div className="cart-image">
        <img src={thumbnail} alt={name} />
      </div>
      <div className="cart-info">
        <span className="cart-title" title={name}>
          {name}
          {/* <Text ellipsis></Text> */}
        </span>
        <span className="cart-price">
          <NumberFormat
            value={price}
            thousandSeparator
            prefix="Rp"
            displayType="text"
          />
        </span>
      </div>
      <div className="cart-info">
        <span className="cart-quantity">Quantity</span>
        <span className="cart-total">{qty}</span>
      </div>
    </div>
  );
};

export default CartCard;
