import React from "react";
import { withRouter, Link } from "react-router-dom";
import {
  FaInstagram,
  FaFacebookSquare,
  FaWhatsapp,
  FaLine,
} from "react-icons/fa";
import { Row, Col, Button } from "antd";
import "./style.less";

const styleLogo = {
  fontSize: "30px",
  color: "black",
  marginRight: "8px",
};

const Footer = (props) => {
  const pathname = props.location.pathname;
  const pathVerif = props.location.pathname.split("/")[1];
  if (
    pathname !== "/login" &&
    pathname !== "/register" &&
    pathVerif !== "reset_password" &&
    pathVerif !== "verify" &&
    pathVerif !== "oauth"
  ) {
    return (
      <div className="footer">
        <div className="container">
          <Row>
            <Col xs={24} sm={24} md={10}>
              <div className="logo logo-footer">SHUMI</div>
              <p>Proud of Your Hobby!</p>

              <a
                href="https://www.instagram.com/shumishoppu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram style={styleLogo} />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=6281295686874&text=Punten%20admin%2c%20aku%20perlu%20bantuan%20%3aD&source=&data=&app_absent="
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp style={styleLogo} />
              </a>
              <a
                href="http://line.me/ti/p/@shumishop"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLine style={styleLogo} />
              </a>
              <a
                href="https://www.facebook.com/shumishoppu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookSquare style={styleLogo} />
              </a>
            </Col>
            <Col xs={24} sm={24} md={7}>
              <div className="footer-link-container">
                <Link to="/faq">
                  <h4 className="footer-link">FAQ</h4>
                </Link>
                <Link to="/terms">
                  <h4 className="footer-link">Terms & Condition</h4>
                </Link>
              </div>
            </Col>
            <Col xs={24} sm={24} md={7}>
              <div className="footer-link-container footer-feedback">
                <Button
                  className="btn-feedback"
                  onClick={() => props.history.push("/feedback")}
                >
                  Kritik Saran
                </Button>
                <h4>Made with Proud by Shumi</h4>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default withRouter(Footer);
