import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

class ProtectedRoute extends Component {
  render() {
    const { component: Component, isLoggedIn, ...props } = this.props;

    return (
      <Route
        {...props}
        render={props => {
          return isLoggedIn == null ? null : isLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps)(ProtectedRoute);
