import { SET_ISADULT, LOADING_START, LOADING_END } from "../constant";

export const setIsAdultItem = (value) => (dispatch) => {
  dispatch({ type: SET_ISADULT, payload: !value });
};

export const startLoading = () => (dispatch) => {
  dispatch({ type: LOADING_START });
};

export const endLoading = () => (dispatch) => {
  dispatch({ type: LOADING_END });
};
