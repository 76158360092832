import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Fukuma from '../../assets/loading/website_load_1.png';
import Bebek from '../../assets/loading/website_load_2.png';
import Mirai from '../../assets/loading/website_load_3.png';

import Fukuma_1 from '../../assets/loading/website_load_1.1.png';
import Mirai_1 from '../../assets/loading/website_load_1.2.png';
import ShumiLogo from '../../assets/loading/website_load_1.3.png';

import MiraiXMAS from '../../assets/loading/website_load_xmas_1.png';
import OriXMAS from '../../assets/loading/website_load_xmas_2.png';
import FukumaXMAS from '../../assets/loading/website_load_xmas_3.png';

import './style.less';

const loading_root = document.getElementById('loading');

const LoadingOne = () => (
  <>
    <img className='loading-mirai' src={Fukuma_1} alt='Fukuma' />
    <img className='loading-bebek' src={ShumiLogo} alt='Shumi Logo' />
    <img className='loading-fukuma' src={Mirai_1} alt='Mirai' />
  </>
);

const LoadingTwo = () => (
  <>
    <img className='loading-fukuma' src={Fukuma} alt='Fukuma' />
    <img className='loading-bebek' src={Bebek} alt='Bebek' />
    <img className='loading-mirai' src={Mirai} alt='Mirai' />
  </>
);

const LoadingThree = () => (
  <>
    <img className='loading-fukuma' src={FukumaXMAS} alt='Fukuma' />
    <img className='loading-bebek' src={OriXMAS} alt='Bebek' />
    <img className='loading-mirai' src={MiraiXMAS} alt='Mirai' />
  </>
);

const LoadingArr = [<LoadingOne />, <LoadingTwo />];

const renderLoadingRandom = () => {
  const angkaRandom = Math.floor(Math.random() * 2);

  return angkaRandom;
};

const Loading = () => {
  const [loadingNumber, setLoadingNumber] = useState(0);

  useEffect(() => {
    const angka = renderLoadingRandom();
    setLoadingNumber(angka);
  }, []);

  return ReactDOM.createPortal(
    <>
      <div className='loading-overlay'></div>
      <div className='loading'>
        <div className='loading-container'>{LoadingArr[loadingNumber]}</div>
      </div>
      <h1 className='loading-text'>Loading . . .</h1>
    </>,
    loading_root
  );
};

export default Loading;
