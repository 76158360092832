import { user } from "../api/shumi";

import {
  GET_ITEM,
  GET_ITEMS,
  SELECT_ITEM,
  GET_TOP_TEN,
  GET_GALLERY,
  CLEAR_GALLERY,
  GET_METADATA_ITEM,
  CLEAR_ITEMS,
  GET_ITEM_ERROR,
  GET_FILTERS,
  GET_SEARCH,
  CLEAR_FILTER,
  GET_PRICE,
  CLEAR_SEARCH,
  GET_CATEGORY,
  GET_FILTER_CATEGORY,
  CLEAR_ALL_FILTER,
  GET_SORT,
  GET_RELATED_ITEM,
  PRE_ORDER,
  READY_STOCK,
  LATE_PRE_ORDER,
  COMING_SOON,
  BACK_ORDER,
  LOADING_START,
  LOADING_END,
  UNDER_300,
  SHUMI_EVENT,
  GET_FLASH_SALE,
} from "../constant";

// Get All Item
export const getItems = () => async (dispatch) => {
  const response = await user.get("/item");
  dispatch({
    type: GET_ITEMS,
    payload: response.data.data.data,
  });
};

export const clearItems = () => (dispatch) => {
  dispatch({ type: CLEAR_ITEMS });
};

export const clearFilter = () => (dispatch) => {
  dispatch({ type: CLEAR_FILTER });
};

export const filterItem =
  (status = "All") =>
  (dispatch) => {
    let statuses = status;
    if (status instanceof Array) {
      statuses = status.map((val) => `&status[]=${val}`).join("");
    } else if (status === SHUMI_EVENT) {
      statuses = SHUMI_EVENT;
    } else if (status === UNDER_300) {
      statuses = UNDER_300;
      console.log(statuses);
    } else if (status !== "All") {
      statuses = `&status[]=${status}`;
    } else {
      statuses = "";
    }
    dispatch({ type: GET_FILTERS, payload: statuses });
  };

export const filterCategory =
  (category = "All") =>
  (dispatch) => {
    let categories = category;
    if (category instanceof Array) {
      categories = category.map((val) => `&category[]=${val}`).join("");
    } else if (category !== "All") {
      categories = `&category[]=${category}`;
    } else {
      categories = "";
    }
    dispatch({ type: GET_FILTER_CATEGORY, payload: categories });
  };

export const filterPrice = (val) => (dispatch) => {
  dispatch({ type: GET_PRICE, payload: { from: val[0], to: val[1] } });
};

export const searchItem =
  (query = "") =>
  (dispatch) => {
    dispatch({ type: GET_SEARCH, payload: query });
  };

export const sortItem =
  (sort = "latest") =>
  (dispatch) => {
    dispatch({ type: GET_SORT, payload: sort });
  };

export const clearSearch = () => (dispatch) => {
  dispatch({ type: CLEAR_SEARCH });
};

export const clearAllFilter = () => (dispatch) => {
  dispatch({ type: CLEAR_ALL_FILTER });
};

export const getRelatedItemByName = (name, id) => async (dispatch) => {
  let response = await user.get(`/item?paginate=10&character[]=${name}`);
  let items = response.data.data.data.filter((item) => item.id !== id);
  if (items.length === 0) {
    response = await user.get(`/item?paginate=10&serie[]=${name}`);
    items = response.data.data.data.filter((item) => item.id !== id);
  }
  dispatch({ type: GET_RELATED_ITEM, payload: items });
};

export const getItemByPage =
  (page = 1, isComingSoon, isBackOrder) =>
  async (dispatch, getState) => {
    dispatch({ type: LOADING_START });
    let response;
    let endpoint;
    if (page === 1) {
      dispatch({ type: CLEAR_ITEMS });
    }

    if (!isComingSoon) {
      if (["", UNDER_300, SHUMI_EVENT].includes(getState().items.filters)) {
        if (isBackOrder) {
          endpoint = `/item?page=${page}&paginate=30&status[]=${PRE_ORDER}&status[]=${READY_STOCK}&status[]=${LATE_PRE_ORDER}&status[]=${BACK_ORDER}`;
        } else {
          endpoint = `/item?page=${page}&paginate=30&status[]=${PRE_ORDER}&status[]=${READY_STOCK}&status[]=${LATE_PRE_ORDER}`;
        }
      } else {
        endpoint = `/item?page=${page}&paginate=30&${getState().items.filters}`;
      }
    } else {
      endpoint = `/item?page=${page}&paginate=30&status[]=${COMING_SOON}`;
    }

    // console.log(getState().items.filtersCategory);
    if (
      getState().items.filtersCategory !== "" &&
      getState().items.filtersCategory !== "All"
    ) {
      endpoint += `${getState().items.filtersCategory}`;
    }

    if (getState().items.query !== "") {
      endpoint = endpoint + `&search=${getState().items.query}`;
    } else if (getState().items.filters === SHUMI_EVENT) {
      endpoint = endpoint + `&search=${SHUMI_EVENT}`;
    }

    if (getState().items.sort !== "") {
      endpoint += `&${getState().items.sort}`;
    }

    if (getState().items.price !== "") {
      endpoint += `&from=${getState().items.price.from}&to=${
        getState().items.price.to
      }`;
    } else if (getState().items.filters === UNDER_300) {
      endpoint += `&from=0&to=300000`;
    }

    endpoint += `&updated`;

    response = await user.get(endpoint);

    const item = response.data.data.data;
    dispatch({ type: GET_ITEMS, payload: item });
    dispatch({
      type: GET_METADATA_ITEM,
      payload: {
        totalData: response.data.data.total,
        limitData: response.data.data.per_page,
        lastPage: response.data.data.last_page,
      },
    });
    dispatch({ type: LOADING_END });
  };

// Get Item by ID
export const getItem = (id) => async (dispatch) => {
  try {
    const response = await user.get(`/item/slug/${id}`);
    dispatch({
      type: GET_ITEM,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ITEM_ERROR,
      payload: error.response.data.data.errors,
    });
  }
};

export const getTopTen = () => async (dispatch) => {
  try {
    const response = await user.get(
      `/item?paginate=10&top_product&status[]=${PRE_ORDER}&status[]=${READY_STOCK}&status[]=${LATE_PRE_ORDER}`
    );
    dispatch({ type: GET_TOP_TEN, payload: response.data.data.data });
  } catch (error) {
    console.log(error);
  }
};

export const getFlashSale = () => async (dispatch) => {
  try {
    const response = await user.get(
      `/item?paginate=10&search=[Flash Sale]&status[]=${PRE_ORDER}&status[]=${READY_STOCK}&status[]=${LATE_PRE_ORDER}`
    );
    dispatch({ type: GET_FLASH_SALE, payload: response.data.data.data });
  } catch (error) {
    console.log(error);
  }
};

export const selectItem = (id) => {
  return {
    type: SELECT_ITEM,
    payload: id,
  };
};

export const getCategory = () => async (dispatch) => {
  const response = await user.get(`/category?paginate=100`);
  const payload = response.data.data.data.map((cat) => cat.name);
  dispatch({ type: GET_CATEGORY, payload: payload });
};

export const getGallery = (id) => async (dispatch) => {
  dispatch({ type: CLEAR_GALLERY });
  try {
    const response = await user.get(`/gallery/${id}`);
    // console.log(response.data.data.data);
    await response.data.data.data.forEach((image) => {
      dispatch({
        type: GET_GALLERY,
        payload: image.images,
      });
    });
  } catch (error) {
    console.log(error.response);
  }
  // dispatch({type:ADD_GALLERY, payload: response.data.})
};

export const addItemView = (id) => () => {
  user.get(`/item/addView/${id}`);
};

// export const getReccomendItem = query => async dispatch => {
//   dispatch({ type: GET_RECCOMEND_ITEM });
// };
