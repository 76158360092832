import {
  GET_TRANSACTION,
  GET_METADATA_TRANSACTION,
  GET_TRANSACTION_DETAIL,
  FAILED_GET_TRANSACTION,
  CLEAR_TRANSACTION,
  GET_SHIPPING_TRACKER,
  FAILED_SHIPPING_TRACKER,
} from '../constant';

const INITIAL_STATE = {
  data: [],
  selectedData: {},
  error: null,
  shippingTrack: null,
  errorShipping: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTION:
      return { ...state, data: action.payload, error: null };
    case GET_METADATA_TRANSACTION:
      return {
        ...state,
        totalData: action.payload.totalData,
        limitData: action.payload.limitData,
      };
    case GET_TRANSACTION_DETAIL:
      return {
        ...state,
        selectedData: action.payload,
        error: null,
      };
    case FAILED_GET_TRANSACTION:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_TRANSACTION:
      return { ...state, data: [] };
    case GET_SHIPPING_TRACKER:
      return { ...state, shippingTrack: action.payload, errorShipping: false };
    case FAILED_SHIPPING_TRACKER:
      return { ...state, errorShipping: true };
    default:
      return state;
  }
};
