import NendoImage from "../assets/logos/Nendo.png";
import ScaleImage from "../assets/logos/SF.png";
import FigmaImage from "../assets/logos/Figma.png";
import LightNovelImage from "../assets/logos/LN.png";
import MangaImage from "../assets/logos/Comic.png";
import ComingSoonImage from "../assets/logos/CS.png";

export const GET_LOGIN = "GET_LOGIN";
export const GET_REGISTER = "GET_REGISTER";
export const GET_LOGOUT = "GET_LOGOUT";
export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const FAILED_LOGIN = "FAILED_LOGIN";
export const RESET_FAILED_LOGIN = "RESET_FAILED_LOGIN";
export const GET_CHECK_USER = "GET_CHECK_USER";
export const FAILED_LOGIN_TOKEN = "FAILED_LOGIN_TOKEN";
export const SUCCESS_VERIFY = "SUCCESS_VERIFY";
export const FAILED_VERIFY = "FAILED_VERIFY";
export const CREATE_RESET_TOKEN = "CREATE_RESET_TOKEN";
export const GET_RESET_TOKEN = "GET_RESET_TOKEN";
export const FAILED_GET_RESET_TOKEN = "FAILED_GET_RESET_TOKEN";
export const GET_RESET_PASSWORD = "GET_RESET_PASSWORD";
export const SUCCESS_UPDATE_GENERAL = "SUCCESS_UPDATE_GENERAL";
export const FAILED_UPDATE_PASSWORD = "FAILED_UPDATE_PASSWORD";
export const FAILED_UPDATE_EMAIL = "FAILED_UPDATE_EMAIL";
export const SUCCESS_UPDATE_EMAIL = "SUCCESS_UPDATE_EMAIL";
export const SUCCESS_VERIFY_UPDATE = "SUCCESS_VERIFY_UPDATE";
export const FAILED_VERIFY_UPDATE = "FAILED_VERIFY_UPDATE";

let BASE_URL = process.env.REACT_APP_SERVICE_URL;

export const BASE_URL_USER = `${BASE_URL}/api/v1`;
export const BASE_URL_ADMIN = `${BASE_URL}/api/v1/shumeme`;
export const BASE_URL_AUTH = `${BASE_URL}/api/v1/user`;
export const BASE_URL_OAUTH = `${BASE_URL}/oauth`;
export const SPACE_URL = process.env.REACT_SPACE_URL;

export const GET_BANNERS = "GET_BANNERS";
export const GET_BANNER = "GET_BANNER";

export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEM = "GET_ITEM";
export const SELECT_ITEM = "SELECT_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const ADD_ITEM = "ADD_ITEM";
export const RESET_ITEM = "RESET_ITEM";
export const FAILED_ADD_ITEM = "FAILED_ADD_ITEM";
export const RESET_ADD_ITEM_ERROR = "RESET_ADD_ITEM_ERROR";
export const GET_TOP_TEN = "GET_TOP_TEN";
export const GET_FLASH_SALE = "GET_FLASH_SALE";
export const GET_GALLERY = "GET_GALLERY";
export const CLEAR_GALLERY = "CLEAR_GALLERY";
export const GET_METADATA_ITEM = "GET_METADATA_ITEM";
export const CLEAR_ITEMS = "CLEAR_ITEMS";
export const GET_ITEM_ERROR = "GET_ITEM_ERROR";
export const GET_SEARCH = "GET_SEARCH";
export const GET_SORT = "GET_SORT";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const GET_RELATED_ITEM = "GET_RELATED_ITEM";

export const GET_FILTERS = "GET_FILTERS";
export const GET_PRICE = "GET_PRICE";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const GET_FILTER_CATEGORY = "GET_FILTER_CATEGORY";
export const CLEAR_ALL_FILTER = "CLEAR_ALL_FILTER";

export const GET_CATEGORY = "GET_CATEGORY";

export const GET_WISHLIST = "GET_WISHLIST";
export const ADD_WISHLIST = "ADD_WISHLIST";
export const DELETE_WISHLIST = "DELETE_WISHLIST";
export const FAILED_GET_WISHLIST = "FAILED_GET_WISHLIST";
export const FAILED_ADD_WISHLIST = "FAILED_ADD_WISHLIST";
export const IS_WISHLISTED = "IS_WISHLISTED";

export const GET_ADDRESSES = "GET_ADDRESSES";
export const GET_ADDRESS = "GET_ADDRESS";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const GET_PROVINCE = "GET_PROVINCE";
export const GET_CITY = "GET_CITY";
export const GET_COST = "GET_COST";
export const CLEAR_COST = "CLEAR_COST";
export const GET_COST_JNE = "GET_COST_JNE";
export const GET_COST_SICEPAT = "GET_COST_SICEPAT";
export const ADD_PRIMARY_ADDRESS = "ADD_PRIMARY_ADDRESS";
export const CLEAR_ADDRESS = "CLEAR_ADDRESS";

export const GET_CART = "GET_CART";
export const ADD_CART = "ADD_CART";
export const DELETE_CART = "DELETE_CART";
export const FAILED_GET_CART = "FAILED_GET_CART";
export const FAILED_ADD_CART = "FAILED_ADD_CART";
export const CLEAR_CART = "CLEAR_CART";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const DELETE_CART_ALL = "DELETE_CART_ALL";
export const CHECKOUT = "CHECKOUT";
export const FAILED_CHECKOUT = "FAILED_CHECKOUT";
export const GET_VOUCHER = "GET_VOUCHER";
export const FAILED_GET_VOUCHER = "FAILED_GET_VOUCHER";
export const RESET_VOUCHER = "RESET_VOUCHER";
export const GET_VOUCHER_WALLET = "GET_VOUCHER_WALLET";
export const CHECKOUT_WALLET = "CHECKOUT_WALLET";
export const FAILED_CHECKOUT_WALLET = "FAILED_CHECKOUT_WALLET";
export const GET_WALLET_TRANSACTION = "GET_WALLET_TRANSACTION";
export const GET_METADATA_WALLET_TRANSACTION =
  "GET_METADATA_WALLET_TRANSACTION";

export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTION_DETAIL = "GET_TRANSACTION_DETAIL";
export const FAILED_GET_TRANSACTION = "FAILED_GET_TRANSACTION";
export const GET_METADATA_TRANSACTION = "GET_METADATA_TRANSACTION";
export const CLEAR_TRANSACTION = "CLEAR_TRANSACTION";
export const GET_SHIPPING_TRACKER = "GET_SHIPPING_TRACKER";
export const FAILED_SHIPPING_TRACKER = "FAILED_SHIPPING_TRACKER";

export const JOINED_GIVEAWAY = "JOINED_GIVEAWAY";
export const COUNT_GIVEAWAY = "COUNT_GIVEAWAY";
export const ERROR_GIVEAWAY = "ERROR_GIVEAWAY";
export const RESET_ERROR_GIVEAWAY = "RESET_ERROR_GIVEAWAY";

export const LOADING_START = "LOADING_START";
export const LOADING_END = "LOADING_END";

export const SET_ISADULT = "SET_ISADULT";

export const indonesiaPhoneRegex = /^08\d{8,20}$/;

export const READY_STOCK = "Ready Stock";
export const PRE_ORDER = "Pre Order";
export const LATE_PRE_ORDER = "Late Pre Order";
export const BACK_ORDER = "Back Order";
export const COMING_SOON = "Coming Soon";
export const UNDER_300 = "Under 300K";
export const SHUMI_EVENT = "Lebaran Sale";

export const category = [
  {
    name: "Nendoroid",
    logo: NendoImage,
    url: "/products",
    query: "?category=Nendoroid",
  },
  {
    name: "Scale Figure",
    logo: ScaleImage,
    url: "/products",
    query: "?category=Scale Figure",
  },
  {
    name: "Figma",
    logo: FigmaImage,
    url: "/products",
    query: "?category=figma",
  },
  {
    name: "Light Novel",
    logo: LightNovelImage,
    url: "/products",
    query: "?category=Japanese Light Novel",
  },
  {
    name: "Manga",
    logo: MangaImage,
    url: "/products",
    query: "?category=Manga",
  },
  { name: "Upcoming Item", logo: ComingSoonImage, url: "/upcoming" },
];

export const paymentMethod = [
  {
    name: "Shumi Wallet",
    img: "https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/shumi%20wallet.png",
    paymentMethod: "points",
    paymentChannel: "points",
    enabled: process.env.REACT_APP_ENABLE_WALLET === "true",
    type: "list",
    paymentOrder: [
      "Pastikan saldo Shumi Wallet kamu mencukupi ya ^^",
      "Lalu click tombol Checkout dan saldo Shumi Wallet kamu akan terpotong otomatis ^^",
      "Sudah deh hehe ^^",
    ],
  },
  {
    name: "Jenius",
    img: "https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/jenius.png",
    paymentMethod: "Jenius",
    paymentChannel: "Jenius",
    paymentStep: "$shuminius",
    enabled: true,
    paymentReceiver: "",
    type: "list",
    paymentOrder: [
      "Buka aplikasi Jenius kamu ^^",
      "Buka menu yang ada di kiri atas layer hadphone kamu ^^",
      "Pilih Send It ^^",
      "Click tombol Tambah Penerima ^^",
      "Ketik $shuminius (bisa dilihat di order history kok ^^) di kolom rekening lalu click Periksa dan click Lanjut ^^",
      "Masukan nominal total harga dengan tepat (bisa dilihat di order history kok ^^) lalu click Lanjut dan click Kirim Uang ^^",
    ],
  },
  {
    name: "OVO",
    img: "https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/ovo.png",
    paymentMethod: "OVO",
    paymentChannel: "OVO",
    paymentStep: "",
    enabled: true,
    paymentReceiver: "",
    type: "tabs",
    // hint: "Maks Pembelian Rp3.000.000",
    paymentOrder: [
      {
        title: "QR Code",
        steps:
          "Silahkan buka aplikasi OVO kamu dan scan barcode dibawah ini ya ^^",
        image: "https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/QROVO.jpg",
      },
      {
        title: "No Telpon",
        steps: [
          "Buka aplikasi OVO kamu ^^",
          'Klik tombol "Transfer" ^^',
          'Klik tombol "Ke Sesama OVO" ^^',
          'Masukan nomor "082211578898" dan masukan nominal transfer sesuai yang tertera di invoice kamu ^^',
          'Klik "Lanjutkan" (pastikan nominal yang kamu kirim sudah benar ya ^^)',
          "Masukan pin OVO kalian ^^",
          "Selesai! (^ - ^)/",
        ],
      },
    ],
  },
  {
    name: "Mandiri",
    img: "https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/mandiri.png",
    paymentMethod: "Mandiri",
    paymentChannel: "Mandiri",
    paymentStep: "1560014564696",
    enabled: true,
    paymentReceiver: "A/N NAUFAL ABDIANTO",
    type: "normal",
    paymentOrder:
      "Kalian bisa Transfer ke nomor rekening 1560014564696 A/N NAUFAL ABDIANTO (bisa dilihat di order history kok ^^) dan pastikan uang yang dikirim sesuai dengan nominal total harga yang tertera ya (bisa dilihat di order history kok ^^)",
  },
  {
    name: "BRI",
    img: "https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/bri.png",
    paymentMethod: "BRI",
    paymentChannel: "BRI",
    enabled: true,
    paymentStep: "791001007140534",
    paymentReceiver: "A/N NAUFAL ABDIANTO",
    type: "normal",
    paymentOrder:
      "Kalian bisa Transfer ke nomor rekening 791001007140534 A/N NAUFAL ABDIANTO (bisa dilihat di order history kok ^^) dan pastikan uang yang dikirim sesuai dengan nominal total harga yang tertera ya (bisa dilihat di order history kok ^^)",
  },
  {
    name: "BCA",
    img: "https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/bca.png",
    paymentMethod: "BCA",
    paymentChannel: "BCA",
    paymentStep: "7285090776",
    enabled: true,
    paymentReceiver: "A/N NAUFAL ABDIANTO",
    type: "tabs",
    paymentOrder: [
      {
        title: "QRku",
        steps: "",
        image: "https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/QRBCA.png",
      },
      {
        title: "No Rekening",
        steps:
          " Kalian bisa Transfer ke nomor rekening 7285090776 A/N NAUFAL ABDIANTO (bisa dilihat di order history kok ^^) dan pastikan uang yang dikirim sesuai dengan nominal total harga yang tertera ya (bisa dilihat di order history kok ^^)",
      },
    ],
  },

  {
    name: "Alfamart",
    img: "https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/alfamart.png",
    paymentMethod: "Ipaymu",
    paymentChannel: "alfamart",
    paymentStep: "",
    enabled: process.env.REACT_APP_ENABLE_ALFAMART === "true",
    paymentReceiver: "",
    type: "list",
    hint: "Maks Pembelian Rp3.000.000",
    paymentOrder: [
      "Datang ke Alfamart terdekat",
      "Lakukan pembayaran ke kasir dengan menyebutkan: PEMBAYARAN PLASAMALL & KODE PEMBAYARAN (KODEPEMBAYARAN)",
      "Bayar sesuai dengan jumlah tagihan anda",
      "Terima dan simpan struk pembayaran Anda.",
      "Pembayaran selesai.",
    ],
  },
  {
    name: "Indomaret",
    img: "https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/indomaret.png",
    paymentMethod: "Ipaymu",
    paymentChannel: "indomaret",
    paymentStep: "",
    paymentReceiver: "",
    enabled: process.env.REACT_APP_ENABLE_INDOMARET === "true",
    type: "list",
    hint: "Maks Pembelian Rp3.000.000",
    paymentOrder: [
      "Datang ke Indomaret terdekat",
      "Lakukan pembayaran ke kasir dengan menyebutkan: PEMBAYARAN PLASAMALL & KODE PEMBAYARAN (KODEPEMBAYARAN)",
      "Bayar sesuai dengan jumlah tagihan anda",
      "Terima dan simpan struk pembayaran Anda.",
      "Pembayaran selesai.",
    ],
  },
];

/*
{
    name: 'Indomaret',
    img: 'https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/indomaret.png',
    paymentMethod: 'Ipaymu',
    paymentChannel: 'indomaret',
    paymentStep: '',
    paymentReceiver: '',
    type: 'list',
    hint: 'Maks Pembelian Rp3.000.000',
    paymentOrder: [
      'Datang ke Indomaret terdekat',
      'Lakukan pembayaran ke kasir dengan menyebutkan: PEMBAYARAN PLASAMALL & KODE PEMBAYARAN (KODEPEMBAYARAN)',
      'Bayar sesuai dengan jumlah tagihan anda',
      'Terima dan simpan struk pembayaran Anda.',
      'Pembayaran selesai.',
    ],
  },
*/

export const faq = [
  {
    question: "Barang di Shumi original semua ga sih?",
    answer:
      "Semua barang di Shumi 100% original jika tidak original kaka bisa hubungi Shumi lalu kita akan refund 120% ^^",
  },
  {
    question:
      "Saya belum punya akun di shumi.shop nih, kalo mau buat kemana ya min?",
    answer: "Kaka klik “Sign In” lalu klik “Register Now!” ^^",
  },
  {
    question: "Kalo saya lupa dengan password akun saya bagaimana min?",
    answer: "Kaka Klik “Sign in” lalu klik “Forgot Password”",
  },
  {
    question: "Kalo beli di Shumi metode pembayarannya bisa pakai apa saja?",
    answer:
      "Shumi menyediakan pembayaran via transfer bank ke Mandiri, BRI dan Jenius, cashless menggunakan OVO, tunai via Alfamart atau Indomart dan menggunakan Shumi Point",
  },
  {
    question: "Shumi Point itu apa min?",
    answer:
      "Shumi Point adalah uang digital dari Shumi untuk membeli item di Shumi yang kalian bisa dapatkan dari membeli figure di Shumi ^^",
  },
  {
    question: "Waahhh!! Bisa top up Shumi Point ga?",
    answer:
      "Cepat atau lambat kita akan menghadirkan fitur top up Shumi Point, namun saat ini kita masih tahap pengembangan ^^ Jadi di tunggu ya (b’-‘)b",
  },
  {
    question: "Bisa COD ga min?",
    answer: "Kita belum menyediakan fitur COD ^^”",
  },
  {
    question: "Min maksudnya Pre-Order itu apa sih?",
    answer:
      "Pre-Order atau PO adalah barang yang dapat di pesan sebelum barang itu siap untuk dikirim ke alamat kaka ^^",
  },
  {
    question: "Terus kalau begitu, itemnya kapan sampainya?",
    answer:
      "Tergantung barangnya, mudahnya kaka bisa liat di deskripsi di bagian “Estimated Arrival” disitu ada perkiraan barang sampai di Indonesia ^^",
  },
  {
    question:
      "Kalo barang Pre-Order udah lewat deadline, saya masih bisa pesan ga?",
    answer:
      "Jika Shumi membuka Late Pre-Order maka kaka masih bisa pesan barang tersebut ^^",
  },
  {
    question: "Late Pre-Order itu apa kak?",
    answer:
      "Late Pre-Order adalah Pre-Order yang sudah lewat deadline namun Shumi masih membuka kesempatan ordernya ^^",
  },
  {
    question: "Back Order itu apa kak?",
    answer:
      "Back Order adalah barang yang sudah rilis dan tidak ada stocknya di Shumi namun masih bisa di order ^^",
  },
  {
    question: "Down Payment itu apa sih?",
    answer:
      "Down Payment atau uang muka adalah bayaran awal Sebagian sebagai tanda jadi bahwa kaka ingin mengorder barang di shumi khususnya Pre-Order, Late Pre-Order dan Back Order ^^",
  },
  {
    question:
      "Misalkan saya pesan Down Payment terlebih dahulu, untuk pelunasannya kapan min?",
    answer:
      "Kaka bisa melunasi Ketika item sudah sampai Indonesia atau kaka bisa melunasi sebelum sampai Indonesia ",
  },
  {
    question:
      "Ketika item sudah sampai Indonesia, saya akan diberi waktu berapa hari untuk melunasi item yang saya?",
    answer:
      "Ketika item sudah sampai Indonesia kaka akan diberi waktu 2 minggu untuk melunasi item kaka namun jika kaka merasa 2 minggu belum cukup kaka bisa sampaikan kepada admin Shumi via whatsapp 082211578898 atau 081395698901 untuk meminta perpanjangan ^^",
  },
  {
    question: "Darimana saya tau kalo item sudah sampai Indonesia?",
    answer:
      "Ketika item sudah sampai Indonesia, kaka akan diberitahu via whatsapp ^^ Jadi pastikan Nomor Whatsapp yang kaka berikan selalu aktif ya ^^",
  },
  {
    question:
      "Ketika item yang sudah sampai, tidak dilunasi apakah Down Payment saya akan di refund/dibalikan ?",
    answer: "Tidak, Karena sejatinya Down Payment tidak bisa dikembalikan ^^",
  },
  {
    question: "Aku bisa cicil barang pre-order aku ga?",
    answer:
      "kaka bisa cicil barang kaka selama barang kaka belum sampai Indonesia, kaka bisa hubungi ke whatsapp 082211578898 atau 081395698901 dan cukup sebutkan  nomor pesanan, barang yang ingin di cicil dan jumlah yang ingin di cicil ^^",
  },
  {
    question:
      "Setelah checkout barang jajanan saya, saya akan diberikan waktu berapa hari untuk membayar?",
    answer: "Shumi akan memberikan waktu 2 hari kak ^^",
  },
  {
    question: "Apakah saya harus konfirmasi pembayaran Ketika sudah bayar?",
    answer:
      "Ga harus, karena admin Shumi setiap jam 6 sampai jam 8 akan mengecek pembayaran yang masuk ke rekening Shumi namun kaka juga bisa upload bukti pembayaran kaka di menu order history ^^",
  },
  {
    question:
      "Jika saya sudah bayar namun dalam 2 hari pembayaran saya belum di terima, apa yang harus saya lakukan?",
    answer:
      "Kaka bisa chat mimin Shumi ke Whatsapp 082211578898 atau 081395698901 ^^",
  },
  {
    question: "Jika saya membeli barang Ready Stock apakah langsung dikirim?",
    answer: "Betul sekali ^^",
  },
  {
    question: "Saya bisa menggunakan jasa pengiriman apa saja di Shumi?",
    answer: "Kaka dapat memakai JNE atau SiCepat untuk pengiriman ^^",
  },
  {
    question: "Kalo saya mendapatkan barang cacat apakah bisa di refund?",
    answer:
      "Bisa, Kaka bisa chat mimin Shumi ke Whatsapp 082211578898 atau 081395698901 ^^",
  },
  {
    question:
      "Kalo Saya mendapatkan barang cacat apakah bisa di tukar barangnya dengan yang baru?",
    answer:
      "Jika stock Ketika di cek masih tersedia maka bisa ditukar dengan yang baru, Kaka bisa chat mimin Shumi ke Whatsapp 082211578898 atau 081395698901 ^^",
  },
  {
    question: "Barang Upcoming bisa di order kak?",
    answer:
      "Ketika status di Shumi masih Upcoming maka belum bisa di order kak, namun Kaka bisa menabung terlebih dahulu menggunakan Shumi Point (Coming Soon) ^^",
  },
  {
    question: "Kapan saya bisa order barang yang statusnya masih Upcoming?",
    answer: "Jika manufaktur dan Shumi sudah membuka Pre-Order barangnya ^^",
  },
];

export const terms = [
  {
    title: "Definisi",
    content: {
      type: "list",
      data: [
        "Shumi adalah suatu perusahaan yang menjalankan kegiatan usaha ritel berbasis online yang berfokus pada barang hobi.",
        "Situs Shumi adalah www.shumi.shop",
        "Syarat & ketentuan adalah perjanjian antara Pengguna dan Shumi yang berisikan seperangkat peraturan yang mengatur hak, kewajiban, tanggung jawab pengguna dan Shumi, serta tata cara penggunaan sistem layanan Shumi.",
        "Pengguna adalah pihak yang menggunakan layanan Shumi untuk melakukan transaksi pembelian.",
        "Barang/Item adalah benda yang berwujud / memiliki fisik Barang yang dapat diantar / memenuhi kriteria pengiriman oleh perusahaan jasa pengiriman Barang.",
        "Rekening Bank Resmi Shumi adalah rekening bank BRI dan Mandiri A/N NAUFAL ABDIANTO dan Jenius dengan castag $shuminius",
        "OVO resmi Shumi adalah 082211578898 A/N NAUFAL ABDIANTO",
        "Fitur pembayaran via Alfamart dan Indomart di tenagai oleh gerbang pembayaran Indonesia iPaymu.",
        "Shumi Point adalah uang digital yang dimiliki dan diKelola oleh Shumi sebagai alternative pembayaran.",
      ],
    },
  },
  {
    title: "Password dan Keamanan",
    content: {
      type: "list",
      data: [
        "Pengguna dengan ini menyatakan bahwa pengguna adalah orang yang cakap dan mampu untuk mengikatkan dirinya dalam sebuah perjanjian yang sah menurut hukum.",
        "Shumi tidak memungut biaya pendaftaran kepada Pengguna.",
        "Pengguna yang telah mendaftar berhak untuk melakukan transaksi pembelian",
        "Shumi tanpa pemberitahuan terlebih dahulu kepada Pengguna, memiliki kewenangan untuk melakukan tindakan yang perlu atas setiap dugaan pelanggaran atau pelanggaran Syarat & ketentuan dan/atau hukum yang berlaku, yakni tindakan berupa transaksi pembelian.",
        "Shumi memiliki kewenangan untuk menutup akun Pengguna baik sementara maupun permanen apabila didapati adanya tindakan kecurangan dalam bertransaksi, pelanggaran terhadap Syarat dan Ketentuan Shumi.",
        "Pengguna memiliki hak untuk melakukan perubahan pada password akun.",
        "Pengguna bertanggung jawab secara pribadi untuk menjaga kerahasiaan akun dan password untuk semua aktivitas yang terjadi dalam akun Pengguna.",
        "Shumi tidak akan meminta username, password maupun link verifikasi milik akun Pengguna untuk alasan apapun, oleh karena itu Shumi menghimbau Pengguna agar tidak memberikan data tersebut maupun data penting lainnya kepada pihak yang mengatasnamakan Shumi atau pihak lain yang tidak dapat dijamin keamanannya.",
        "Pengguna setuju untuk memastikan bahwa Pengguna keluar dari akun di akhir setiap sesi.",
        "Pengguna dengan ini menyatakan bahwa Shumi tidak bertanggung jawab atas kerugian ataupun kendala yang timbul atas penyalahgunaan akun Pengguna yang diakibatkan oleh kelalaian Pengguna, termasuk namun tidak terbatas pada meminjamkan atau memberikan akses akun kepada pihak lain, mengakses link atau tautan yang diberikan oleh pihak lain, password atau email kepada pihak lain, maupun kelalaian Pengguna lainnya yang mengakibatkan kerugian ataupun kendala pada akun Pengguna.",
      ],
    },
  },
  {
    title: "Transaksi Pembelian",
    content: {
      type: "list",
      data: [
        "Pembeli wajib bertransaksi melalui prosedur transaksi yang telah ditetapkan oleh Shumi. Pembeli melakukan pembayaran dengan menggunakan metode pembayaran yang sebelumnya telah dipilih oleh Pembeli",
        {
          type: "list",
          title: "Saat melakukan pembelian Barang, Pembeli menyetujui bahwa:",
          data: [
            "Pembeli bertanggung jawab untuk membaca, memahami, dan menyetujui informasi/deskripsi keseluruhan Barang (termasuk didalamnya namun tidak terbatas pada warna, kualitas, fungsi, dan lainnya) sebelum membuat tawaran atau komitmen untuk membeli.",
            "Pembeli mengakui bahwa warna sebenarnya dari produk sebagaimana terlihat di situs Shumi tergantung pada monitor komputer Pembeli. Shumi telah melakukan upaya terbaik untuk memastikan warna dalam foto-foto yang ditampilkan di Situs Shumi muncul seakurat mungkin, tetapi tidak dapat menjamin bahwa penampilan warna pada Situs Shumi akan akurat.",
            "Pengguna masuk ke dalam kontrak yang mengikat secara hukum untuk membeli Barang ketika Pengguna membeli suatu barang.",
          ],
        },
        "Pembeli memahami dan menyetujui bahwa ketersediaan stok Barang merupakan tanggung jawab Shumi, Jika ada kesalahan jumlah stock yang merugikan pengguna maka pengguna berhak mendapatkan refund.",
        "Pembeli memahami sepenuhnya dan menyetujui bahwa segala transaksi yang dilakukan antara Pembeli dan Shumi selain melalui Rekening Resmi Shumi dan/atau tanpa sepengetahuan Shumi (melalui fasilitas/jaringan pribadi, pengiriman pesan, pengaturan transaksi khusus diluar situs Shumi atau upaya lainnya) adalah merupakan tanggung jawab Pembeli.",
        "Shumi memiliki kewenangan sepenuhnya untuk menolak pembayaran tanpa pemberitahuan terlebih dahulu.",
        "Pembayaran oleh Pembeli wajib dilakukan segera (selambat-lambatnya dalam batas waktu 2 hari) setelah Pembeli melakukan check-out. Jika dalam batas waktu tersebut pembayaran atau konfirmasi pembayaran belum dilakukan oleh pembeli, Shumi memiliki kewenangan untuk membatalkan transaksi dimaksud. Pengguna tidak berhak mengajukan klaim atau tuntutan atas pembatalan transaksi tersebut.",
        "Pelunasan oleh Pembeli wajib dilakukan segera sesuai dengan waktu yang diberikan yaitu 2 minggu. Jika pembeli merasa tidak bisa melunasi pembeli mendapat hak untuk diberikan kerenggangan waktu hingga paling lama 1 bulan jika tidak maka uang DP akan hangus.",
        "Konfirmasi pembayaran dilakukan dengan otomatis dan tidak diharuskan meng input bukti pembayaran, kecuali dalam jangka 1 x 24 jam Shumi tidak mengkonfirmasi maka wajib untuk menginput bukti pembayaran. Shumi tidak bertanggung jawab jika ada pembayaran yang terlewat begitu saja namun tidak di konfirmasi oleh pembeli.",
        "Pembeli menyetujui untuk tidak memberitahukan atau menyerahkan bukti pembayaran dan/atau data pembayaran kepada pihak lain selain Shumi. Dalam hal terjadi kerugian akibat pemberitahuan atau penyerahan bukti pembayaran dan/atau data pembayaran oleh Pembeli kepada pihak lain, maka hal tersebut akan menjadi tanggung jawab Pembeli.",
        "Pembeli memahami dan menyetujui bahwa setiap masalah pengiriman Barang yang disebabkan keterlambatan pembayaran adalah merupakan tanggung jawab dari Pembeli",
        "Pembeli memahami dan menyetujui bahwa masalah keterlambatan proses pembayaran dan biaya tambahan yang disebabkan oleh perbedaan bank yang Pembeli pergunakan dengan bank Rekening resmi Shumi adalah tanggung jawab Pembeli secara pribadi.",
        {
          type: "list",
          title:
            "Pengembalian dana dari Shumi kepada Pembeli hanya dapat dilakukan jika dalam keadaan-keadaan tertentu berikut ini:",
          data: [
            "Kelebihan pembayaran dari Pembeli atas harga Barang,",
            "Masalah pengiriman Barang telah teridentifikasi secara jelas dari Shumi yang mengakibatkan pesanan Barang tidak sampai",
            "Shumi tidak bisa menyanggupi order karena kehabisan stok, perubahan ongkos kirim, maupun penyebab lainnya",
          ],
        },
        "Apabila terjadi proses pengembalian dana, maka pengembalian akan diproses langsung ke rekening pembeli.",
        "Apabila Pembeli memilih menggunakan metode pembayaran transfer bank, maka total pembayaran akan ditambahkan kode unik untuk mempermudah proses verifikasi. Dalam hal pembayaran telah diverifikasi maka kode unik akan dikembalikan ke Saldo Refund Pembeli.",
        "Pembeli wajib melakukan pembayaran dengan nominal yang sesuai dengan jumlah tagihan beserta kode unik (apabila ada) yang tertera pada halaman pembayaran. Shumi tidak bertanggungjawab atas kerugian yang dialami Pembeli apabila melakukan pembayaran yang tidak sesuai dengan jumlah tagihan yang tertera pada halaman pembayaran.",
      ],
    },
  },
  {
    title: "Harga",
    content: {
      type: "list",
      data: [
        "Harga Barang yang terdapat dalam situs Shumi adalah harga yang ditetapkan oleh Shumi.",
        "Shumi bertanggung jawab atas kesalahan ketik yang menyebabkan keterangan harga atau informasi lain menjadi tidak benar/sesuai . Perlu diingat dalam hal ini, apabila terjadi kesalahan pengetikan keterangan harga Barang yang tidak disengaja, Shumi berhak menolak pesanan Barang yang dilakukan oleh pembeli.",
        "Dengan melakukan pemesanan melalui Shumi, Pengguna menyetujui untuk membayar total biaya yang harus dibayarkan sebagaimana tertera dalam halaman pembayaran, yang terdiri dari harga barang, ongkos kirim, dan biaya-biaya lain yang mungkin timbul dan akan diuraikan secara tegas dalam halaman pembayaran. Pengguna setuju untuk melakukan pembayaran melalui metode pembayaran yang telah dipilih sebelumnya oleh Pengguna.",
        "Situs Shumi untuk saat ini hanya melayani transaksi jual beli Barang dalam mata uang Rupiah.",
      ],
    },
  },
  {
    title: "Tarif Pengiriman",
    content: {
      type: "text",
      data: "Pembeli memahami dan mengerti bahwa Shumi sudah berusaha sebaik mungkin untuk mempertanggungjawabkan tarif pengiriman yang ditagihkan kepada Pembeli.Tarif pengiriman yang ada di Shumi berdasarkan API dari www.rajaongkir.com. Jika ada perubahan tarif ongkir dari perusahaan jasa pengiriman yang tidak sesuai dengan tarif yang ada di Shumi maka, Shumi bertanggung jawab sepenuhnya atas perbedaan tarif pengiriman tersebut.",
    },
  },
  {
    title: "Jenis Barang",
    content: {
      type: "list",
      data: [
        "Segala bentuk produk di Shumi merupakan barang Original.",
        "Filter untuk produk di atas 18 tahun adalah tanggung jawab pengguna untuk mengaktifkan atau me non-aktifkan.",
        "Hampir semua barang di Shumi dalam keadaan baru, namun beberapa barang memiliki kondisi tidak baru. Jika ada barang yang kondisinya tidak baru Shumi pasti memberikan keterangan pada produk tersebut.",
        "Jika ada barang Shumi yang memiliki cacat pada kotak atau kemasan produk, Shumi akan segera menginfokan pembeli.",
      ],
    },
  },
];
