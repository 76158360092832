import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';

import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';

import App from './App';
import reducers from './reducers';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn:
    'https://f8f9239f22be4bac8df3bd3a873c8e87@o419500.ingest.sentry.io/5333750',
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const preloadedState = window.__PRELOADED_STATE__;

// // Allow the passed state to be garbage-collected
// delete window.__PRELOADED_STATE__;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

// Tell react-snap how to save Redux state
// window.snapSaveState = () => ({
//   __PRELOADED_STATE__: store.getState(),
// });

const rootElement = document.getElementById('root');
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <Provider store={store}>
//       <App />
//     </Provider>,
//     rootElement
//   );
// } else {
render(
  <Provider store={store}>
    <>
      <App />
    </>
  </Provider>,
  rootElement
);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
