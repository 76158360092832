import React from 'react';
import ContainerTitle from '../components/ContainerTitle';
import { terms } from '../constant';
import scrollTop from '../utility/scrollTop';
import withAnalytics from '../components/withAnalytics';
import SEO from '../components/SEO';

const TermsCondition = () => {
  scrollTop(false);
  return (
    <div className='container container-terms'>
      <SEO title='Terms' />
      <ContainerTitle title='Terms and Condition' />
      <ol className='terms-title'>
        {terms.map(({ title, content }, idx) => (
          <li key={idx}>
            <h3>{title}</h3>
            {content.type === 'list' ? (
              <ol>
                {content.data.map((value, i) =>
                  typeof value === 'object' ? (
                    <li key={`${value}-${i}`}>
                      <p>{value.title}</p>
                      <ol className='terms-sub'>
                        {value.data.map((val) => (
                          <li key={val}>
                            <p>{val}</p>
                          </li>
                        ))}
                      </ol>
                    </li>
                  ) : (
                    <li key={`${value}-${i}`}>
                      <p>{value}</p>
                    </li>
                  )
                )}
              </ol>
            ) : (
              <p>{content.data}</p>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default withAnalytics(TermsCondition);
