import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import { Card, Tooltip, Button, Skeleton, Tabs } from 'antd';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import ContainerTitle from '../components/ContainerTitle';
import { paymentMethod } from '../constant';
import {
  getTransactionDetail,
  getTransactionDetailItem,
} from '../actions/transactionAction';
import SEO from '../components/SEO';
import NotFoundPage from '../pages/404';
import scrollTop from '../utility/scrollTop';
import withAnalytics from '../components/withAnalytics';

const { TabPane } = Tabs;

class DetailOrder extends Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    scrollTop();
    const { getTransactionDetail, getTransactionDetailItem } = this.props;

    const orderId = this.props.match.params.id;
    const orderItemId = this.props.match.params.orderId;

    // console.log(orderItemId);
    if (orderItemId != null) {
      await getTransactionDetailItem(orderId, orderItemId);
    } else {
      await getTransactionDetail(orderId);
    }
    this.setState({ loading: false });
  }

  renderPaymentStep = (payment, paymentCode = null) => {
    if (Array.isArray(payment.paymentOrder) && payment.type === 'list') {
      return (
        <ol>
          {payment.paymentOrder.map((val, i) => {
            const res = val.replace('(KODEPEMBAYARAN)', paymentCode);
            return <li key={i}>{res}</li>;
          })}
        </ol>
      );
    } else if (payment.type === 'tabs') {
      return (
        <Tabs>
          {payment.paymentOrder.map((order, i) => {
            return (
              <TabPane tab={order.title} key={order.title}>
                {Array.isArray(order.steps) ? (
                  <ol>
                    {order.steps.map((step, j) => (
                      <li key={j}>{step}</li>
                    ))}
                  </ol>
                ) : (
                  <p>{order.steps}</p>
                )}
                {order.image && (
                  <img
                    src={order.image}
                    alt={order.title}
                    style={{ width: '100%' }}
                  />
                )}
              </TabPane>
            );
          })}
        </Tabs>
      );
    } else {
      return <p>{payment.paymentOrder}</p>;
    }
  };

  renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <h1>Expired</h1>;
    } else {
      // Render a countdown
      return (
        <h1>
          {days} days : {hours}H : {minutes}M : {seconds}S
        </h1>
      );
    }
  };

  render() {
    const { loading } = this.state;
    const { error } = this.props;

    if (loading) {
      return (
        <div className='container'>
          <ContainerTitle title='Payment' />
          <div className='thanks-card'>
            <Card>
              <Skeleton paragraph={{ rows: 1 }} active title={false} />
              <h3>
                <Skeleton paragraph={{ rows: 1 }} active title={false} />
              </h3>
              {/* <div className="thanks-countdown">
                <h4>Time Left</h4>
                <h1>48h : 59m : 30s</h1>
              </div> */}
              <div className='thanks-info'>
                <div>
                  <h3>Grand Total</h3>
                  <Skeleton paragraph={{ rows: 1 }} active title={false} />
                </div>
                <div className='thanks-content'>
                  <h3>Payment Method</h3>
                  <Skeleton
                    avatar={{ shape: 'square' }}
                    active
                    title={false}
                    paragraph={false}
                  />
                  <h5 style={{ marginTop: '16px' }}>
                    <Skeleton paragraph={{ rows: 1 }} active title={false} />
                  </h5>
                  <Skeleton paragraph={{ rows: 1 }} active title={false} />
                </div>
                <div className='thanks-content'>
                  <h3>How to pay</h3>
                  <Skeleton active title={false} paragraph={{ rows: 5 }} />
                </div>
              </div>
            </Card>
          </div>
        </div>
      );
    } else if (error) {
      return <NotFoundPage errors='Order does not Exist' subtitle={error} />;
    } else {
      const {
        id,
        payment: {
          payment_expired,
          total_payment,
          payment_channel,
          remain_payment,
          payment_code,
        },
        shipment
      } = this.props.order;

      const orderItemId = this.props.match.params.orderId;

      const payment = paymentMethod.find(
        (val) => val.paymentChannel === payment_channel
      );

      let totalPayment;
      let uniqueCode;

      if (orderItemId == null) {
        totalPayment = total_payment
          .toString()
          .substr(0, total_payment.toString().length - 3);
        uniqueCode = total_payment.toString().substr(-3);
      } else {
        let shipmentPayment = shipment.shipment_cost
        totalPayment = (remain_payment + shipmentPayment)
          .toString()
          .substr(0, remain_payment.toString().length - 3);
        uniqueCode = remain_payment.toString().substr(-3);
      }

      const isIpaymu =
        payment_expired.substring(payment_expired.length - 3) === 'WIB';

      let timeLeft = moment(payment_expired).valueOf();

      if (isIpaymu) {
        timeLeft = moment(
          payment_expired.substring(0, payment_expired.length - 4)
        ).valueOf();
      }

      return (
        <div className='container'>
          <SEO title={`Order ID #${id}`} />
          <ContainerTitle title='Payment' />
          <div className='thanks-card'>
            <Card>
              <h4 className='thanks-id'>Order ID #{id}</h4>
              <h3>Please complete your payment before {payment_expired}</h3>
              <div className='thanks-countdown'>
                <h4>Time Left</h4>
                <Countdown date={timeLeft} renderer={this.renderCountdown} />
              </div>
              <div className='thanks-info'>
                <div>
                  <h3>Grand Total</h3>
                  <h4>
                    <NumberFormat
                      prefix='Rp'
                      displayType='text'
                      thousandSeparator
                      value={totalPayment}
                    />
                    <span>
                      {payment.paymentMethod !== 'Ipaymu' ? (
                        <Tooltip
                          placement='bottom'
                          title='Transfer hingga 3 digit terakhir'
                          visible
                        >
                          ,{uniqueCode}
                        </Tooltip>
                      ) : (
                        `,${uniqueCode}`
                      )}
                    </span>
                  </h4>
                </div>
                <div className='thanks-content'>
                  <h3>Payment Method</h3>
                  <div className='thanks-image'>
                    <img src={payment.img} alt='Mandiri' />
                  </div>
                  <h5>
                    <b>{payment.paymentStep}</b>
                  </h5>
                  <h5>{payment.paymentReceiver}</h5>
                  {payment.paymentMethod === 'Ipaymu' && (
                    <>
                      <h3 className='thanks-code'>Payment Code</h3>
                      <h2 className='thanks-code-payment'>
                        <NumberFormat
                          displayType='text'
                          thousandSeparator=' '
                          value={payment_code}
                        />
                      </h2>
                    </>
                  )}
                </div>
                <div className='thanks-content'>
                  <h3>How to pay</h3>
                  {this.renderPaymentStep(payment, payment_code)}
                </div>
              </div>
              <Link to='/order'>
                <Button className='thanks-back'>
                  <LeftOutlined />
                  Back to Order
                </Button>
              </Link>
            </Card>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  order: state.transaction.selectedData,
  error: state.transaction.error,
  loading: state.global.loading,
});

export default withAnalytics(
  connect(mapStateToProps, {
    getTransactionDetail,
    getTransactionDetailItem,
  })(DetailOrder)
);
