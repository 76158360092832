import {
  ADD_CART,
  GET_CART,
  DELETE_CART,
  FAILED_GET_CART,
  FAILED_ADD_CART,
  CLEAR_CART,
  CLEAR_ERROR,
  DELETE_CART_ALL,
  CHECKOUT,
  CLEAR_SEARCH,
  GET_VOUCHER,
  RESET_VOUCHER,
  FAILED_CHECKOUT,
  GET_VOUCHER_WALLET,
  CHECKOUT_WALLET,
  GET_WALLET_TRANSACTION,
  GET_METADATA_WALLET_TRANSACTION,
} from '../constant';

const INITIAL_STATE = {
  data: [],
  wallet_transaction: [],
  wallet_metadata: { totalData: null },
  selectedData: {},
  isSuccessAdd: false,
  selectedId: '',
  error: null,
  isVoucherValid: null,
  voucher: {},
  recentCheckoutId: null,
  errorCheckout: null,
  isCheckoutWalletSuccess: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CART:
      return { ...state, data: action.payload };
    case ADD_CART:
      return {
        ...state,
        isSuccessAdd: true,
      };
    case DELETE_CART:
      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.filter((item) => item.id !== action.payload),
        },
      };
    case FAILED_GET_CART:
      return { ...state, error: action.payload };
    case CLEAR_CART:
      return { ...state, data: [] };
    case CLEAR_SEARCH:
      return { ...state, query: '' };
    case FAILED_ADD_CART:
      return { ...state, error: action.payload };
    case CLEAR_ERROR:
      return { ...state, error: null };
    case DELETE_CART_ALL:
      return { ...state, data: [] };
    case CHECKOUT:
      return {
        ...state,
        isCheckoutSuccess: true,
        data: [],
        recentCheckoutId: action.payload,
      };
    case CHECKOUT_WALLET:
      return {
        ...state,
        isCheckoutWalletSuccess: true,
      };
    case FAILED_CHECKOUT:
      return { ...state, errorCheckout: action.payload };
    case GET_VOUCHER:
      return {
        ...state,
        isVoucherValid:
          action.payload != null && action.payload.type === 'Item',
        voucher: action.payload,
      };
    case GET_VOUCHER_WALLET:
      return {
        ...state,
        isVoucherValid:
          action.payload != null && action.payload.type === 'Point',
        voucher: action.payload,
      };
    case RESET_VOUCHER:
      return {
        ...state,
        isVoucherValid: null,
        voucher: {},
      };
    case GET_WALLET_TRANSACTION:
      return {
        ...state,
        wallet_transaction: action.payload,
      };
    case GET_METADATA_WALLET_TRANSACTION:
      return {
        ...state,
        wallet_metadata: { ...action.payload },
      };
    default:
      return state;
  }
};
