import React from "react";

import {
  CheckOutlined,
  CloseOutlined,
  HeartOutlined,
  HomeOutlined,
  LogoutOutlined,
  StopOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";

import { Popover, Divider, Row, Col, message, Switch } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import SW from "../../../assets/images/sw.png";

import { logout } from "../../../actions/authAction";
import "./style.less";
import NumberFormat from "react-number-format";

const ProfileContainer = ({
  name,
  logout,
  point,
  handleChange,
  isLegal,
  handleLogout,
}) => {
  const content = (
    <div className="profile-popover">
      <div>
        <Row gutter={16}>
          <Col span={14}>
            <div className="point-menu">
              <h4>Shumi Wallet</h4>
              <p>
                <img
                  src={SW}
                  alt=""
                  width="50px"
                  style={{ marginTop: "2px" }}
                />
                <NumberFormat
                  displayType="text"
                  value={point}
                  prefix="."
                  thousandSeparator
                />
              </p>
            </div>
          </Col>
          <Col span={10}>
            {/* <div className="profile-menu">
              <Link to="/profile">
                <Icon type="user" />
                <span>Profile</span>
              </Link>
            </div> */}
            <div className="profile-switch">
              <div className="profile-switch-content">
                <StopOutlined />
                <span>18+ Items</span>
              </div>
              <div>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={isLegal}
                  onChange={(checked) => handleChange(checked)}
                />
              </div>
            </div>

            <Link to="/wallet" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
              <div className="profile-menu">
                <WalletOutlined />
                <span>Wallet</span>
              </div>
            </Link>
            <Link to="/wishlist" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
              <div className="profile-menu">
                <HeartOutlined />
                <span>Wishlist</span>
              </div>
            </Link>
            <Link to="/address" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
              <div className="profile-menu">
                <HomeOutlined />
                <span>Address</span>
              </div>
            </Link>
            <Link to="/profile" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
              <div className="profile-menu">
                <UserOutlined />
                <span>Account</span>
              </div>
            </Link>
            <div
              className="profile-menu profile-logout"
              onClick={async () => {
                await message.loading("Logout ..");
                try {
                  logout();
                  handleLogout();
                  message.success("Success Logout");
                } catch (error) {
                  message.error("Error Logout");
                }
              }}
            >
              <LogoutOutlined />
              <span>Logout</span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );

  return (
    <Popover content={content} placement="bottomRight">
      <Divider type="vertical" />
      <div className="profile-nav">
        <span>{name.split(" ")[0]}</span>
      </div>
    </Popover>
  );
};

export default connect(null, { logout })(ProfileContainer);
