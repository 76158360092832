import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyUpdateEmail } from '../actions/authAction';
import withAnalytics from '../components/withAnalytics';

const VerificationUpdateEmail = (props) => {
  // console.log(props);
  const token = props.match.params.token;
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isSuccess = useSelector((state) => state.auth.isVerifyUpdate);
  const errors = useSelector((state) => state.auth.errorVerifyUpdate);

  useEffect(() => {
    (async () => {
      await dispatch(verifyUpdateEmail(token));
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='login-wrapper'>
        <div className='logo logo-login logo-verification'>SHUMI</div>
        <div className='login-form verification'>
          {loading ? (
            <p>Loading . . .</p>
          ) : isSuccess ? (
            <p>Your account is successfully verified </p>
          ) : (
            <>
              {errors.map((val, i) => (
                <p style={{ color: 'red' }} key={i}>
                  {val}
                </p>
              ))}
            </>
          )}
        </div>
      </div>
      <div className='bg-login'></div>
    </>
  );
};

export default withAnalytics(VerificationUpdateEmail);
