import React from 'react';
import * as Yup from 'yup';
import { Modal } from 'antd';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { submitSuggestion } from '../actions/authAction';
import Announcement from '../components/Announcement';
import AddFeedbackContainer from '../components//AddFeedbackContainer';
import { indonesiaPhoneRegex } from '../constant';
import SEO from '../components/SEO';
import withAnalytics from '../components/withAnalytics';

const { success } = Modal;

const Feedback = () => {
  const dispatch = useDispatch();

  const initialValues = {
    name: '',
    phone: '',
    suggestion: '',
  };

  const FeedbackSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    phone: Yup.string()
      .matches(indonesiaPhoneRegex, 'Phone number is not valid')
      .required('Required'),
    suggestion: Yup.string()
      .required('Required')
      .test(
        'len',
        'Minimal 20 Characters',
        (val) => val && val.toString().length >= 20
      ),
  });

  const handleSubmit = async (props) => {
    dispatch(submitSuggestion(props));
    success({
      title:
        'Wah terima kasih banyak ya! Feedback kamu sangat berarti bagi Shumi loh! ^^',
      okText: 'OK',
    });
  };

  return (
    <>
      <SEO title='Feedback' />
      <Announcement announcement='Submit Your Feedback ^^' />
      <div className='container container-feedback'>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values);
            resetForm();
          }}
          validationSchema={FeedbackSchema}
        >
          {(props) => <AddFeedbackContainer {...props} />}
        </Formik>
      </div>
    </>
  );
};

export default withAnalytics(Feedback);
