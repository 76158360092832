import {
  ADD_ADDRESS,
  DELETE_ADDRESS,
  GET_ADDRESS,
  GET_ADDRESSES,
  GET_PROVINCE,
  GET_CITY,
  CLEAR_COST,
  GET_COST_SICEPAT,
  GET_COST_JNE,
  CLEAR_ADDRESS,
} from "../constant";

const INITIAL_STATE = {
  data: [],
  totalAddress: null,
  selectedData: {},
  addedData: {},
  searchedData: [],
  province: [],
  city: [],
  shippingJNE: null,
  shippingSicepat: null,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADDRESSES:
      return { ...state, data: action.payload, totalAddress: action.totalData };
    case GET_ADDRESS:
      return { ...state, selectedData: action.payload };
    case ADD_ADDRESS:
      return {
        ...state,
      };
    case DELETE_ADDRESS:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case CLEAR_ADDRESS:
      return { ...state, data: [] };
    case GET_PROVINCE:
      return { ...state, province: action.payload };
    case GET_CITY:
      return { ...state, city: action.payload };
    case CLEAR_COST:
      return { ...state, shipping: [] };
    case GET_COST_JNE:
      return { ...state, shippingJNE: action.payload };
    case GET_COST_SICEPAT:
      return { ...state, shippingSicepat: action.payload };
    default:
      return state;
  }
};
