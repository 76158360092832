import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

import NotFoundImage from "../assets/images/404.png";
import SEO from "../components/SEO";

const NotFound = () => (
  <img src={NotFoundImage} alt="404 Not Found Icon" height="200" />
);

const NotFoundPage = ({ errors, subtitle, isSEODisable }) => {
  return (
    <>
      {isSEODisable && <SEO title={errors ? errors : "404 Page Not Found"} />}

      <Result
        icon={<NotFound />}
        title={errors ? errors : "404 Page Not Found"}
        subTitle={errors ? subtitle : "Anda mau ke mana"}
        extra={
          !errors && (
            <Link to="/">
              <Button type="primary">Back Home</Button>
            </Link>
          )
        }
      />
    </>
  );
};

export default NotFoundPage;
