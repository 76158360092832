import {
  ADD_ITEM,
  DELETE_ITEM,
  GET_ITEM,
  GET_ITEMS,
  RESET_ITEM,
  SELECT_ITEM,
  FAILED_ADD_ITEM,
  RESET_ADD_ITEM_ERROR,
  GET_TOP_TEN,
  GET_GALLERY,
  CLEAR_GALLERY,
  GET_METADATA_ITEM,
  CLEAR_ITEMS,
  GET_ITEM_ERROR,
  GET_FILTERS,
  GET_SEARCH,
  CLEAR_FILTER,
  CLEAR_SEARCH,
  GET_PRICE,
  GET_CATEGORY,
  GET_FILTER_CATEGORY,
  CLEAR_ALL_FILTER,
  GET_SORT,
  IS_WISHLISTED,
  GET_RELATED_ITEM,
  GET_FLASH_SALE,
} from "../constant";

const INITIAL_STATE = {
  data: [],
  topTen: [],
  flashSale: [],
  selectedGallery: [],
  selectedData: {},
  totalData: null,
  limitData: null,
  lastPage: 1,
  isSuccessAdd: false,
  selectedId: "",
  error: [],
  filters: "",
  query: "",
  price: "",
  category: [],
  related: [],
  sort: "",
  filtersCategory: "",
  isWishlisted: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ITEMS:
      return { ...state, data: state.data.concat(action.payload) };
    case GET_ITEM:
      return { ...state, selectedData: action.payload };
    case GET_RELATED_ITEM:
      return { ...state, related: action.payload };
    case GET_CATEGORY:
      return { ...state, category: action.payload };
    case CLEAR_ITEMS:
      return { ...state, data: [], totalData: null, limitData: null };
    case CLEAR_FILTER:
      return { ...state, filters: "" };
    case CLEAR_ALL_FILTER:
      return {
        ...state,
        filters: "",
        filtersCategory: "",
        query: "",
        price: "",
        sort: "",
      };
    case CLEAR_SEARCH:
      return { ...state, query: "" };
    case GET_METADATA_ITEM:
      return {
        ...state,
        totalData: action.payload.totalData,
        limitData: action.payload.limitData,
        lastPage: action.payload.lastPage,
      };
    case GET_TOP_TEN:
      return { ...state, topTen: action.payload };
    case GET_FLASH_SALE:
      return { ...state, flashSale: action.payload };
    case ADD_ITEM:
      return {
        ...state,
        data: [action.payload, ...state.data],
        isSuccessAdd: true,
      };
    case DELETE_ITEM:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case RESET_ITEM:
      return { ...state, selectedData: {} };
    case SELECT_ITEM:
      return { ...state, selectedId: action.payload };
    case FAILED_ADD_ITEM:
      return { ...state, error: action.payload };
    case RESET_ADD_ITEM_ERROR:
      return { ...state, error: [] };
    case CLEAR_GALLERY:
      return { ...state, selectedGallery: [] };
    case GET_GALLERY:
      return {
        ...state,
        selectedGallery: [action.payload, ...state.selectedGallery],
      };
    case GET_ITEM_ERROR:
      return { ...state, error: action.payload };
    case GET_FILTERS:
      return { ...state, filters: action.payload };
    case GET_FILTER_CATEGORY:
      return { ...state, filtersCategory: action.payload };
    case GET_SORT:
      return { ...state, sort: action.payload };
    case GET_SEARCH:
      return { ...state, query: action.payload };
    case GET_PRICE:
      return { ...state, price: action.payload };
    case IS_WISHLISTED:
      return { ...state, isWishlisted: action.payload };
    default:
      return state;
  }
};
