import { combineReducers } from 'redux';
import authReducer from './authReducer';
import bannerReducer from './bannerReducer';
import itemReducer from './itemReducer';
import wishlistReducer from './wishlistReducer';
import addressReducer from './addressReducer';
import cartReducer from './cartReducer';
import globalReducer from './globalReducer';
import transactionReducer from './transactionReducer';
import giveawayReducer from './giveawayReducer';

export default combineReducers({
  auth: authReducer,
  items: itemReducer,
  banner: bannerReducer,
  wishlist: wishlistReducer,
  address: addressReducer,
  cart: cartReducer,
  transaction: transactionReducer,
  giveaway: giveawayReducer,
  global: globalReducer,
});
