import axios from 'axios';
import { user } from '../api/shumi';
import {
  GET_CART,
  ADD_CART,
  DELETE_CART,
  FAILED_ADD_CART,
  FAILED_GET_CART,
  CLEAR_CART,
  CLEAR_ERROR,
  DELETE_CART_ALL,
  LOADING_START,
  LOADING_END,
  CHECKOUT,
  GET_VOUCHER,
  RESET_VOUCHER,
  FAILED_GET_VOUCHER,
  FAILED_CHECKOUT,
  GET_VOUCHER_WALLET,
  CHECKOUT_WALLET,
  FAILED_CHECKOUT_WALLET,
  GET_WALLET_TRANSACTION,
  GET_METADATA_WALLET_TRANSACTION,
} from '../constant';

const CancelToken = axios.CancelToken;
let cancelVoucherSeach;

//API RAJA ONGKIR 9507c25f4e8a8bf6ade5a8854d4f2d3c

export const getCart =
  (clear = true) =>
  async (dispatch) => {
    dispatch({ type: LOADING_START });
    if (clear) {
      dispatch({ type: CLEAR_CART });
    }
    dispatch({ type: CLEAR_ERROR });
    try {
      const response = await user.get('/user/cart');
      dispatch({
        type: GET_CART,
        payload: response.data.data,
      });
    } catch (e) {
      dispatch({
        type: FAILED_GET_CART,
        payload: e.response.data,
      });
    }
    dispatch({ type: LOADING_END });
  };

export const updateCart = (id, payload) => async (dispatch) => {
  try {
    await user.post(`/user/cart/update_qty/${id}`, payload);
  } catch (e) {
    dispatch({
      type: FAILED_GET_CART,
      payload: e.response.data,
    });
  }
};

export const postCart = (payload) => async (dispatch) => {
  try {
    const response = await user.post('/user/cart', payload);
    // console.log(response.data);
    if (response.data.data === 'Item Data Is Exist') {
      dispatch({
        type: FAILED_ADD_CART,
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: ADD_CART,
        payload: response.data,
      });
    }
  } catch (e) {
    // console.log(e);
    dispatch({
      type: FAILED_ADD_CART,
      payload: e.response,
    });
  }
};

export const deleteCart = (id) => async (dispatch) => {
  try {
    await user.delete(`/user/cart/${id}`);
    dispatch({
      type: DELETE_CART,
      payload: id,
    });
  } catch (e) {
    dispatch({
      type: FAILED_GET_CART,
      payload: e.response.data,
    });
  }
};

export const clearCart = () => async (dispatch) => {
  try {
    await user.get(`/user/cart/delete_all`);
    dispatch({
      type: DELETE_CART_ALL,
    });
  } catch (e) {
    dispatch({
      type: FAILED_GET_CART,
      payload: e.response.data,
    });
  }
};

export const nyobaReducer = () => (dispatch) => {
  return 'Aang';
};

export const checkout = (payload) => async (dispatch) => {
  dispatch({ type: LOADING_START });
  let response;
  try {
    response = await user.post('/user/cart/checkout', payload);
    dispatch({ type: CHECKOUT, payload: response.data.data });
    dispatch({ type: RESET_VOUCHER });
  } catch (e) {
    dispatch({
      type: FAILED_CHECKOUT,
      payload: e.response.data.data.errors,
    });
  }
  dispatch({ type: LOADING_END });
  // console.log(response.data.data);
  if (response.data.data) {
    return response.data.data;
  }
};

export const checkoutRemain = (payload, id) => async (dispatch) => {
  dispatch({ type: LOADING_START });
  let response;
  try {
    response = await user.post(`/user/transaction/pay_item/${id}`, payload);
    dispatch({ type: CHECKOUT, payload: response.data.data });
  } catch (error) {
    console.log(error.response);
  }
  dispatch({ type: RESET_VOUCHER });
  dispatch({ type: LOADING_END });
  // console.log(response.data.data);
  return response.data.data;
};

export const getVoucherDetail = (voucher, voucherType) => async (dispatch) => {
  dispatch({ type: LOADING_START });

  cancelVoucherSeach && cancelVoucherSeach();

  try {
    const response = await user.get(`/user/voucher/${voucher}`, {
      cancelToken: new CancelToken((c) => {
        cancelVoucherSeach = c;
      }),
    });

    if (voucherType === 'item') {
      dispatch({ type: GET_VOUCHER, payload: response.data.data });
    } else {
      dispatch({ type: GET_VOUCHER_WALLET, payload: response.data.data });
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      // console.log("Request Cancelled");
    } else {
      dispatch({ type: FAILED_GET_VOUCHER });
    }
  }
  dispatch({ type: LOADING_END });
};

export const resetVoucher = () => (dispatch) => {
  dispatch({ type: RESET_VOUCHER });
};

export const checkoutWallet = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch({ type: LOADING_START });
    try {
      const response = await user.post(
        '/user/transaction_point/checkout',
        payload
      );
      dispatch({ type: CHECKOUT_WALLET, payload: response.data });
      dispatch({ type: RESET_VOUCHER });
      resolve(true);
    } catch (e) {
      dispatch({
        type: FAILED_CHECKOUT_WALLET,
        payload: e.response.data.data.errors,
      });
      reject(e.response.data);
    }
    dispatch({ type: LOADING_END });
  });
};

export const getWalletTransaction =
  (page = 1, paginate = 8) =>
  (dispatch) =>
    new Promise(async (res, rej) => {
      dispatch({ type: LOADING_START });
      try {
        const response = await user.get(
          `/user/transaction_point?page=${page}&paginate=${paginate}`
        );
        dispatch({
          type: GET_WALLET_TRANSACTION,
          payload: response.data.data.data,
        });
        dispatch({
          type: GET_METADATA_WALLET_TRANSACTION,
          payload: {
            totalData: response.data.data.total,
            limitData: response.data.data.per_page,
          },
        });
        res();
      } catch (error) {
        rej();
      }
      dispatch({ type: LOADING_END });
    });

export const uploadWalletReceipt = (payload, id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: LOADING_START });
    try {
      const data = new FormData();
      data.append('receipt', payload);
      await user.post(`/user/transaction_point/upload_receipt/${id}`, data);
      resolve(true);
    } catch (error) {
      reject(error.response.data);
    }
    dispatch({ type: LOADING_END });
  });
