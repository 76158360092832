import React from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import './style.less';

function ContactAdmin() {
  return (
    <div className='contact-wrapper'>
      <Tooltip placement='topLeft' title='Need Help? Click Here'>
        <a
          href='https://api.whatsapp.com/send?phone=6281295686874&text=Punten%20admin%2c%20aku%20perlu%20bantuan%20%3aD&source=&data=&app_absent='
          target='_blank'
          rel='noopener noreferrer'
        >
          <div className='contact-button'>
            <MailOutlined style={{ color: 'black' }} />
          </div>
        </a>
      </Tooltip>
    </div>
  );
}

export default ContactAdmin;
