import { auth as shumi, user } from '../api/shumi';

import {
  ADD_ADDRESS,
  DELETE_ADDRESS,
  GET_ADDRESS,
  GET_ADDRESSES,
  GET_PROVINCE,
  GET_CITY,
  GET_COST_JNE,
  GET_COST_SICEPAT,
  CLEAR_COST,
  ADD_PRIMARY_ADDRESS,
  CLEAR_ADDRESS,
} from '../constant';

// Get All Address
export const getAddresses = () => async (dispatch) => {
  const response = await shumi.get('/address');
  // console.log(response.data);
  dispatch({
    type: CLEAR_COST,
  });
  dispatch({
    type: GET_ADDRESSES,
    payload: response.data.data.data,
    totalData: response.data.data.total,
  });
};

// Get Address by ID
export const getAddress = (id) => async (dispatch) => {
  const response = await shumi.get(`/address/${id}`);
  dispatch({
    type: GET_ADDRESS,
    payload: response.data.data,
  });
};

export const getAddressAndMain = () => async (dispatch, getState) => {
  const responseAddress = await shumi.get('/address');
  const responseAddressMain = await await shumi.get(
    `/address/${getState().auth.user.addressMain}`
  );
  dispatch({
    type: CLEAR_COST,
  });
  dispatch({
    type: GET_ADDRESSES,
    payload: responseAddress.data.data.data,
    totalData: responseAddress.data.data.total,
  });
  dispatch({
    type: GET_ADDRESS,
    payload: responseAddressMain.data.data,
  });
};

// Add new Address
export const postAddress = (payload) => async (dispatch, getState) => {
  try {
    const response = await shumi.post('/address', payload);
    dispatch({
      type: ADD_ADDRESS,
      payload: response.data.data,
    });
  } catch (error) {
    console.log(error.response);
  }
};

//Update Address
export const updateAddress = (id, payload) => async (dispatch) => {
  // console.log(id, payload);
  const response = await shumi.post(`/address/${id}`, payload);
  if (response.status === 200) {
    const getAll = await shumi.get('/address');
    dispatch({
      type: GET_ADDRESSES,
      payload: getAll.data.data.data,
    });
  }
};

// Delete Address
export const deleteAddress = (id) => async (dispatch) => {
  await shumi.delete(`/address/${id}`);
  dispatch({
    type: DELETE_ADDRESS,
    payload: id,
  });
};

export const clearAddress = () => (dispatch) => {
  dispatch({ type: CLEAR_ADDRESS });
};

export const setAsPrimaryAddress = (id) => async (dispatch) => {
  await shumi.post(`/address/setMain`, id);
  dispatch({ type: ADD_PRIMARY_ADDRESS, payload: id });
};

export const getProvince = () => async (dispatch) => {
  const response = await user.get('/shipment/province');
  dispatch({
    type: GET_PROVINCE,
    payload: response.data.rajaongkir.results,
  });
};

export const getCity = (province) => async (dispatch, getState) => {
  const idProvince = await getState().address.province.find(
    (prov) => prov.province === province
  );
  const id = await idProvince.province_id;

  // console.log(idProvince, id);

  const response = await user.get(`/shipment/city/${id}`);
  // console.log(response.data.rajaongkir.results);
  dispatch({
    type: GET_CITY,
    payload: response.data.rajaongkir.results,
  });
};

export const getCostJNE = ({ destination, weight }) => async (dispatch) => {
  try {
    const response = await user.post(
      `/shipment/cost/55/${destination}/${weight}/jne`
    );
    // console.log(response.data.rajaongkir.results);
    dispatch({
      type: GET_COST_JNE,
      payload: response.data.rajaongkir.results[0],
    });
  } catch (e) {
    console.log(e.response);
  }
};

export const getCostSicepat = ({ destination, weight }) => async (dispatch) => {
  try {
    const response = await user.post(
      `/shipment/cost/55/${destination}/${weight}/sicepat`
    );
    // console.log(response.data.rajaongkir.results);
    dispatch({
      type: GET_COST_SICEPAT,
      payload: response.data.rajaongkir.results[0],
    });
  } catch (e) {
    console.log(e.response);
  }
};
