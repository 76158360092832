import React from "react";
// import "@ant-design/compatible/assets/index.css";
import { DatePicker, Input, TimePicker, Select, InputNumber, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";

const FormItem = Form.Item;
const { Option } = Select;

const CreateAntField =
  (AntComponent) =>
  ({
    field,
    form,
    hasFeedback,
    label,
    selectOptions,
    submitCount,
    onChangeCustom,
    customOnChange,
    customSelectOptions,
    customSelectRender,
    type,
    isCity,
    disabled,
    ...props
  }) => {
    const touched = form.touched[field.name];
    const submitted = submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = ({ target: { value } }) =>
      form.setFieldValue(field.name, value);
    const onChange = (value) => form.setFieldValue(field.name, value);
    const onBlur = () => form.setFieldTouched(field.name, true);

    const handleCustomChange = (val) => {
      form.setFieldValue(field.name, val);
      onChangeCustom(val);
    };

    // console.log(disabled);
    return (
      <FormItem
        label={label}
        hasFeedback={
          (hasFeedback && submitted) || (hasFeedback && touched) ? true : false
        }
        help={submittedError || touchedError ? hasError : false}
        validateStatus={submittedError || touchedError ? "error" : "success"}
      >
        <AntComponent
          {...field}
          {...props}
          type={type}
          onBlur={onBlur}
          disabled={disabled}
          onChange={
            type
              ? onInputChange
              : customOnChange
              ? handleCustomChange
              : onChange
          }
        >
          {selectOptions &&
            selectOptions.map((name, i) => (
              <Option key={`${name}`}>{name}</Option>
            ))}
        </AntComponent>
      </FormItem>
    );
  };

export const AntSelect = CreateAntField(Select);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntInput = CreateAntField(Input);
export const AntTimePicker = CreateAntField(TimePicker);
export const AntInputNumber = CreateAntField(InputNumber);
export const AntInputTextArea = CreateAntField(TextArea);
