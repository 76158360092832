import React, { Component } from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Result, Button } from "antd";

import ErrorImage from "../../assets/images/500.png";

class ErrorBoundaries extends Component {
  state = {
    error: false,
  };

  static getDerivedStateFromError(err) {
    return { error: true };
  }
  componentDidCatch(error, info) {
    console.log(error, info);
  }

  renderErrorImage = () => {
    return <img src={ErrorImage} alt="500 Error" height="200" />;
  };

  render() {
    if (this.state.error)
      return (
        <div className="error-boundries">
          <Result
            // status="500"
            icon={<LegacyIcon type={this.renderErrorImage()} />}
            title="Error 500"
            subTitle="Coba pencet F12, lihat errornya terus lapor ke shumi D:"
            extra={
              <a href="/">
                <Button type="primary">Back Home</Button>
              </a>
            }
          />
        </div>
      );
    return this.props.children;
  }
}

export default ErrorBoundaries;
