import { LOADING_START, LOADING_END, SET_ISADULT } from "../constant";

const INITIAL_STATE = {
  loading: false,
  isAdult: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_START:
      return { ...state, loading: true };
    case LOADING_END:
      return { ...state, loading: false };
    case SET_ISADULT:
      return { ...state, isAdult: action.payload };
    default:
      return state;
  }
};
