export default function (isNotSmooth) {
  try {
    let behavior = 'smooth';
    if (isNotSmooth) {
      behavior = 'auto';
    }
    window.scroll({
      behavior: behavior,
      top: 0,
    });
  } catch (err) {
    if (err instanceof TypeError) {
      window.scroll(0, 0);
    } else {
      throw err;
    }
  }
}
