import { user } from '../api/shumi';
import {
  LOADING_START,
  LOADING_END,
  GET_TRANSACTION,
  GET_TRANSACTION_DETAIL,
  FAILED_GET_TRANSACTION,
  GET_METADATA_TRANSACTION,
  CLEAR_TRANSACTION,
  GET_SHIPPING_TRACKER,
  FAILED_SHIPPING_TRACKER,
} from '../constant';

export const getTransaction = (page = 1, paginate = 5, key = null) => async (
  dispatch
) => {
  dispatch({ type: LOADING_START });
  try {
    let base = 'transaction';
    let params = '&waiting_payment';

    switch (key) {
      case 'Waiting Payment':
        params = '&waiting_payment';
        break;
      case 'Waiting Confirmation':
        params = '&waiting_confirmation';
        break;
      case 'Waiting Item Confirmation':
        base = 'transaction_item';
        params = '&waiting_confirmation';
        break;
      case 'Expired':
        params = '&transaction_expired';
        break;
      case 'Order Processed':
        params = '&payment_success';
        break;
      case 'Order Shipped':
        base = 'transaction_item';
        params = '&shipment';
        break;
      default:
        params = '&waiting_payment';
    }

    let url = `/user/${base}?page=${page}&paginate=${paginate}${params}`;

    const response = await user.get(url);
    // console.log(response.data);
    dispatch({
      type: GET_TRANSACTION,
      payload: response.data.data.data,
    });
    dispatch({
      type: GET_METADATA_TRANSACTION,
      payload: {
        totalData: response.data.data.total,
        limitData: response.data.data.per_page,
      },
    });
  } catch (e) {
    dispatch({
      type: FAILED_GET_TRANSACTION,
      payload: e.response.data,
    });
  }
  dispatch({ type: LOADING_END });
};

export const clearTransaction = () => (dispatch) => {
  dispatch({ type: CLEAR_TRANSACTION });
};

export const getTransactionDetail = (id) => async (dispatch) => {
  dispatch({ type: LOADING_START });

  const response = await user.get(`/user/transaction/${id}`);
  if (response.data.data) {
    dispatch({
      type: GET_TRANSACTION_DETAIL,
      payload: response.data.data,
    });
  } else {
    dispatch({
      type: FAILED_GET_TRANSACTION,
      payload: 'Check your order ID',
    });
  }

  dispatch({ type: LOADING_END });
};

export const getTransactionDetailItem = (id, orderId) => async (dispatch) => {
  dispatch({ type: LOADING_START });
  try {
    const response = await user.get(`/user/transaction/item/${id}/${orderId}`);
    dispatch({
      type: GET_TRANSACTION_DETAIL,
      payload: response.data.data,
    });
  } catch (e) {
    dispatch({
      type: FAILED_GET_TRANSACTION,
      payload: e.response.data,
    });
  }
  dispatch({ type: LOADING_END });
};

export const postTransactionItemReceipt = (payload, id) => async (dispatch) => {
  try {
    await user.post(`/user/transaction/upload_receipt_item/${id}`, payload);
  } catch (e) {
    console.log(e.response);
  }
};

export const postTransactionReceipt = (payload, id) => async (dispatch) => {
  const formdata = new FormData();
  formdata.append('receipt', payload);
  dispatch({ type: LOADING_START });
  try {
    await user.post(`/user/transaction/upload_receipt/${id}`, formdata);
  } catch (e) {
    console.log(e.response);
  }
  dispatch({ type: LOADING_END });
};

export const getShippingTracker = (waybill, method) => async (dispatch) => {
  try {
    const response = await user.post(`/shipment/waybill/${waybill}/${method}`);
    // console.log(response.data);
    dispatch({
      type: GET_SHIPPING_TRACKER,
      payload: response.data.rajaongkir,
    });
    return response.data.rajaongkir;
  } catch (error) {
    dispatch({ type: FAILED_SHIPPING_TRACKER });
  }
};
