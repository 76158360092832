import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';

import SEO from '../components/SEO';
import ContainerTitle from '../components/ContainerTitle';
import { faq } from '../constant';
import scrollTop from '../utility/scrollTop';
import withAnalytics from '../components/withAnalytics';
const { Panel } = Collapse;

const customPanelStyle = {
  background: '#fafafafa',
  borderRadius: 4,
  marginBottom: 8,
  border: 0,
  overflow: 'hidden',
};

const spanStyle = {
  fontWeight: 'normal',
  marginBottom: 0,
};

const FAQ = () => {
  scrollTop(false);
  return <>
    <SEO title='FAQ' />
    <div className='container'>
      <ContainerTitle title='Frequently Asked Question (FAQ)' />
      <Collapse
        accordion
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        {faq.map((val, i) => (
          <Panel
            header={<h4>{val.question}</h4>}
            key={i}
            style={customPanelStyle}
          >
            <p style={spanStyle}>{val.answer}</p>
          </Panel>
        ))}
      </Collapse>
    </div>
  </>;
};

export default withAnalytics(FAQ);
