import React, { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { admin, user, auth } from "./api/shumi";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// LESS Files
import "./assets/index.less";
// Actions
import { checkLoggedIn } from "./actions/authAction";
import ErrorBoundaries from "./components/ErrorBoundaries";
import Loading from "./components/Loading";
// import WalletTopUp from "./pages/wallettopup";
import ProtectedRoute from "./components/ProtectedRoute";
import DetailOrder from "./pages/detailorder";
import ContactAdmin from "./components/ContactAdmin";
import Feedback from "./pages/feedback";
import FAQ from "./pages/faq";
import TermsCondition from "./pages/termscondition";
import withAnalytics from "./components/withAnalytics";
import OAuthPage from "./pages/oauth";
import CacheBuster from "./CacheBuster";
import VerificationUpdateEmail from "./pages/verificationUpdateEmail";
import { getAddresses } from "./actions/addressAction";
import Maintenance from "./pages/maintenance";

// Pages Homew
const Home = lazy(() => import("./pages/home"));

const Product = lazy(() => import("./pages/product"));
const verification = lazy(() => import("./pages/verification"));
const forgotPassword = lazy(() => import("./pages/forgotPassword"));
const resetPasswordWithToken = lazy(() =>
  import("./pages/resetPasswordWithToken")
);
const Address = lazy(() => import("./pages/address"));
const DetailProduct = lazy(() => import("./pages/detailproduct"));
const Login = lazy(() => import("./pages/login"));
const Register = lazy(() => import("./pages/register"));
const Transaction = lazy(() => import("./pages/transaction"));
const Profile = lazy(() => import("./pages/profile"));
const Cart = lazy(() => import("./pages/cart"));
const SendValidation = lazy(() => import("./pages/sendValidation"));
const Wishlist = lazy(() => import("./pages/wishlist"));
const comingsoon = lazy(() => import("./pages/comingsoon"));
const NotFoundPage = lazy(() => import("./pages/404"));
const wallet = lazy(() => import("./pages/wallet"));
const Giveaway = lazy(() => import("./pages/giveaway"));

const transactionTracker = withAnalytics(Transaction);
const isMaintenance = false;

const App = () => {
  const dispatch = useDispatch();
  // const address = useSelector((state) => state.address);
  // const pathname = window.location.pathname.split('/')[0];

  useEffect(() => {
    // Intialize Token

    admin.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    user.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    auth.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    ReactGA.initialize("UA-149843297-1");
    const isAdult = localStorage.getItem("isLegal");

    if (!isAdult) {
      localStorage.setItem("isLegal", "0");
    }

    dispatch(checkLoggedIn());
    // dispatch(getAddresses());
  }, []);

  // useEffect(() => {
  //   if (pathname !== 'address' && address && address.length) {
  //     address.forEach((val) => {
  //       if (val.phone.length === 5) {
  //         console.log('Masuk');
  //         // window.location.replace('/address');
  //       }
  //     });
  //   }
  // }, [address]);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        if (isMaintenance) {
          return <Maintenance />;
        }
        return (
          <ErrorBoundaries>
            <div className="app-wrapper">
              <BrowserRouter>
                <Route
                  render={({ location }) => (
                    <Suspense fallback={<Loading />}>
                      <Navbar />
                      <TransitionGroup>
                        <CSSTransition
                          key={location.key}
                          timeout={300}
                          classNames="fade"
                        >
                          <Switch location={location}>
                            <Route path="/login" exact component={Login} />
                            <Route
                              path="/register"
                              exact
                              component={Register}
                            />
                            <Route
                              path="/reset_password"
                              exact
                              component={forgotPassword}
                            />
                            <Route
                              path="/reset_password/:token"
                              exact
                              component={resetPasswordWithToken}
                            />
                            <Route
                              path="/oauth/:email/:token"
                              exact
                              component={OAuthPage}
                            />
                            <Route
                              path="/verify"
                              exact
                              component={SendValidation}
                            />
                            <Route
                              path="/verify/email_change/:token"
                              exact
                              component={VerificationUpdateEmail}
                            />
                            <Route
                              path="/"
                              component={() => (
                                <div className="content-wrapper">
                                  <Switch>
                                    <Route path="/" exact component={Home} />
                                    <Route path="/faq" exact component={FAQ} />
                                    {/* <Route
                                      path='/giveaway'
                                      exact
                                      component={Giveaway}
                                    /> */}
                                    <Route
                                      path="/terms"
                                      exact
                                      component={TermsCondition}
                                    />
                                    <Route
                                      path="/feedback"
                                      exact
                                      component={Feedback}
                                    />
                                    <Route
                                      path="/verify/:token"
                                      exact
                                      component={verification}
                                    />

                                    <ProtectedRoute
                                      path="/address"
                                      exact
                                      component={Address}
                                    />
                                    <ProtectedRoute
                                      path="/profile"
                                      exact
                                      component={Profile}
                                    />
                                    <Route
                                      path="/products"
                                      component={Product}
                                    />
                                    <Route
                                      path="/upcoming"
                                      component={comingsoon}
                                    />
                                    <Route
                                      path="/product/:id"
                                      exact
                                      component={DetailProduct}
                                    />
                                    <ProtectedRoute
                                      path="/cart"
                                      exact
                                      component={Cart}
                                    />
                                    <ProtectedRoute
                                      path="/wishlist"
                                      component={Wishlist}
                                    />
                                    <ProtectedRoute
                                      path="/order"
                                      exact
                                      component={Transaction}
                                    />
                                    <ProtectedRoute
                                      path="/order/:id"
                                      exact
                                      component={DetailOrder}
                                    />
                                    <ProtectedRoute
                                      path="/order/:id/:orderId"
                                      exact
                                      component={DetailOrder}
                                    />
                                    <ProtectedRoute
                                      path="/wallet"
                                      exact
                                      component={wallet}
                                    />
                                    <ProtectedRoute
                                      path="/profile"
                                      exact
                                      component={Profile}
                                    />
                                    <Route component={NotFoundPage} />
                                  </Switch>
                                </div>
                              )}
                            />
                            <Route component={NotFoundPage} />
                          </Switch>
                          {/* <Route path="/wallet/topup" exact component={WalletTopUp} /> */}
                        </CSSTransition>
                      </TransitionGroup>
                      <Footer />
                    </Suspense>
                  )}
                />
              </BrowserRouter>
              <ContactAdmin />
            </div>
          </ErrorBoundaries>
        );
      }}
    </CacheBuster>
  );
};

export default App;
