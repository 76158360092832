import React, { Component } from "react";
import { MenuOutlined, SwapOutlined } from "@ant-design/icons";
import { Button, Badge } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./style.less";
import CartContainer from "../CartContainer";
import ProfileContainer from "./ProfileContainer";
import SearchBar from "../Searchbar";
import Sidebar from "./Sidebar";
import { setIsAdultItem } from "../../actions/globalAction";

class Navbar extends Component {
  state = {
    visible: false,
    isLegal: false,
  };

  componentDidMount() {
    const { setIsAdultItem } = this.props;
    const isVerified = localStorage.getItem("isLegal");
    // console.log(isVerified);
    if (isVerified === "1") {
      this.setState({ isLegal: true });
    }
    setIsAdultItem(isVerified === "1");
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleLogout = () => {
    localStorage.setItem("isLegal", 0);
    setIsAdultItem(false);
  };

  render() {
    const pathname = this.props.location.pathname;
    const pathVerif = this.props.location.pathname.split("/")[1];
    const {
      auth: { user, isLoggedIn },
      setIsAdultItem,
      cart,
    } = this.props;
    if (
      isLoggedIn == null &&
      pathname !== "/login" &&
      pathname !== "/register" &&
      pathVerif !== "reset_password" &&
      pathVerif !== "verify" &&
      pathVerif !== "oauth"
    ) {
      return (
        <>
          <div className="navb ar">
            <div className="container">
              <div className="nav-container">
                <Link to="/">
                  <div className="logo">
                    <span>SHUMI</span>
                  </div>
                </Link>
                <div className="search-bar">
                  <SearchBar />
                </div>

                <div className="item-link item-menu">
                  <MenuOutlined style={{ fontSize: "16px" }} />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      if (
        pathname !== "/login" &&
        pathname !== "/register" &&
        pathVerif !== "reset_password" &&
        pathVerif !== "verify" &&
        pathVerif !== "oauth"
      ) {
        return (
          <>
            <Sidebar
              visible={this.state.visible}
              onClose={this.onClose}
              isLoggedIn={isLoggedIn}
              point={user.point}
            />
            <div className="navbar">
              <div className="container">
                <div className="nav-container">
                  <Link to="/">
                    <div className="logo">
                      <span>SHUMI</span>
                    </div>
                  </Link>
                  <div className="search-bar">
                    <SearchBar />
                  </div>
                  {isLoggedIn && (
                    <>
                      <div className="item-link item-login">
                        <Link to="/order">
                          <SwapOutlined style={{ fontSize: "24px" }} />
                        </Link>
                      </div>
                      <div className="item-link item-login">
                        <CartContainer />
                      </div>
                    </>
                  )}

                  {!isLoggedIn && (
                    <div className="item-link item-login">
                      <Link to="/login">
                        <Button type="primary"> Sign in</Button>
                      </Link>
                    </div>
                  )}

                  {isLoggedIn && (
                    <div className="item-link item-login">
                      <ProfileContainer
                        name={user.name}
                        point={user.point}
                        handleLogout={this.handleLogout}
                        isLegal={this.state.isLegal}
                        handleChange={(checked) => {
                          if (!checked) {
                            localStorage.setItem("isLegal", 0);
                            setIsAdultItem(false);
                          } else {
                            localStorage.setItem("isLegal", 1);
                            setIsAdultItem(true);
                          }
                          this.setState({ isLegal: !this.state.isLegal });
                        }}
                      />
                    </div>
                  )}

                  <div
                    className="item-link item-menu"
                    onClick={() => {
                      this.setState({
                        visible: !this.state.visible,
                      });
                    }}
                  >
                    <Badge count={cart && cart.total_item}>
                      <MenuOutlined style={{ fontSize: "16px" }} />
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return null;
      }
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart.data.cart,
});

export default withRouter(connect(mapStateToProps, { setIsAdultItem })(Navbar));
