import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Badge, Popover, Empty } from "antd";
import NumberFormat from "react-number-format";

import EmptyCart from "../../assets/images/cempty.png";

import CartCard from "../CartCard";

import { getCart } from "../../actions/cartAction";

const CartContainer = ({ cart, items, getCart, ...props }) => {
  //   const [isVisible, setVisibility] = useState(false);
  const [isLoading, setLoading] = useState(true);
  //   console.log(cart, items);

  useEffect(() => {
    (async () => {
      await getCart();
      setLoading(false);
    })();
  }, [getCart]);

  return isLoading ? (
    <Link to="/cart">
      <ShoppingCartOutlined style={{ fontSize: "24px" }} />
    </Link>
  ) : (
    <Badge count={cart && cart.total_item}>
      <Popover
        placement="bottomRight"
        content={
          cart ? (
            <div>
              <div className="cart-container">
                {items &&
                  items.map(({ item, ...val }, i) => (
                    <CartCard
                      key={i}
                      name={item.name}
                      price={item.price}
                      qty={val.qty}
                      thumbnail={item.thumbnail}
                    />
                  ))}
              </div>
              <div className="cart-footer">
                <span style={{ flexGrow: 1 }}>
                  Total Items: <b>{cart && cart.total_item}</b>
                </span>
                <span style={{ flexGrow: 1 }}>
                  Total Price:{" "}
                  <b>
                    <NumberFormat
                      value={cart && cart.total_price_all}
                      thousandSeparator
                      prefix="Rp"
                      displayType="text"
                    />
                  </b>
                </span>
                <Link
                  to="/cart"
                  // onClick={() => {
                  //   setVisibility(!isVisible);
                  // }}
                >
                  <span style={{ textAlign: "right" }}>See All</span>
                </Link>
              </div>
            </div>
          ) : (
            <Empty
              image={EmptyCart}
              description="Cart Masih Kosong ( • ₃ • )"
            />
          )
        }
        // onClick={() => {
        //   setVisibility(!isVisible);
        // }}
        // visible={isVisible}
        // trigger="click"
      >
        <Link to="/cart">
          <ShoppingCartOutlined style={{ fontSize: "24px" }} />
        </Link>
      </Popover>
    </Badge>
  );
};

const mapStateToProps = (state) => ({
  items: state.cart.data.data,
  cart: state.cart.data.cart,
});

export default connect(mapStateToProps, { getCart })(CartContainer);
