import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'antd';
import queryString from 'query-string';

import {
  searchItem,
  getItemByPage,
  clearItems,
} from '../../actions/itemAction';

const { Search } = Input;

class SearchBar extends Component {
  async componentDidMount() {
    const pathname = this.props.location.pathname;
    pathname === '/products' && this.handleSearch();
  }

  handleSearch = async (value) => {
    const { searchItem, getItemByPage, clearItems, query } = this.props;
    const parsed = await queryString.parse(this.props.history.location.search);
    if (parsed.q !== query) {
      // console.log(parsed);
      await searchItem(parsed.q);
      // await clearItems();
      // await getItemByPage(1, false, true);
    }
  };

  toSearchPage = (value) => {
    const path = this.props.history.location.pathname;
    if (value !== '') {
      this.props.history.push({
        pathname: '/products',
        search: `?q=${value}`,
      });
    } else {
      this.props.history.push({
        pathname: '/products',
      });
    }
    this.handleSearch();
  };

  render() {
    const parsed = queryString.parse(this.props.history.location.search);
    return (
      <Search
        placeholder='Search'
        defaultValue={parsed.q}
        onSearch={this.toSearchPage}
        enterButton
      />
    );
  }
}

const mapStateToProps = (state) => ({
  query: state.items.query,
});

export default connect(mapStateToProps, {
  searchItem,
  getItemByPage,
  clearItems,
})(withRouter(SearchBar));
