import { GET_BANNER, GET_BANNERS } from "../constant";

const INITIAL_STATE = {
  data: [],
  selectedData: {}
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BANNERS:
      return { ...state, data: action.payload };
    case GET_BANNER:
      return { ...state, selectedData: action.payload };
    default:
      return state;
  }
};
