import {
  ADD_WISHLIST,
  GET_WISHLIST,
  DELETE_WISHLIST,
  FAILED_GET_WISHLIST,
  FAILED_ADD_WISHLIST
} from "../constant";

const INITIAL_STATE = {
  data: [],
  selectedData: {},
  isSuccessAdd: false,
  selectedId: "",
  error: null
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_WISHLIST:
      return { ...state, data: action.payload };
    case ADD_WISHLIST:
      return {
        ...state,
        data: [action.payload, ...state.data],
        isSuccessAdd: true
      };
    case DELETE_WISHLIST:
      return {
        ...state,
        data: state.data.filter(item => item.id !== action.payload)
      };
    case FAILED_GET_WISHLIST:
      return { ...state, error: action.payload };
    case FAILED_ADD_WISHLIST:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
