import {
  JOINED_GIVEAWAY,
  ERROR_GIVEAWAY,
  RESET_ERROR_GIVEAWAY,
  COUNT_GIVEAWAY,
} from '../constant';

const INITIAL_STATE = {
  data: null,
  count: 0,
  isJoined: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case JOINED_GIVEAWAY:
      return {
        ...state,
        isJoined: action.payload != null,
        data: action.payload,
      };
    case ERROR_GIVEAWAY:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_ERROR_GIVEAWAY:
      return {
        ...state,
        error: null,
      };
    case COUNT_GIVEAWAY:
      return {
        ...state,
        count: action.payload,
      };
    default:
      return state;
  }
};
