import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  HeartOutlined,
  HomeOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
  SwapOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';

import { Drawer, Button, message, Badge } from 'antd';
import NumberFormat from 'react-number-format';

import Fukuma from '../../../assets/images/regis1.png';
import Mirai from '../../../assets/images/regis2.png';
import SW from '../../../assets/images/sw.png';

import { logout } from '../../../actions/authAction';
import './index.less';

class Sidebar extends Component {
  redirect = (url) => {
    this.props.history.push(url);
    this.props.onClose();
  };
  render() {
    const { visible, onClose, isLoggedIn, point, cart } = this.props;
    return (
      <Drawer
        placement='right'
        closable
        width='100%'
        onClose={onClose}
        visible={visible}
      >
        <div className='drawer-bg'>
          <img src={Fukuma} alt='Fukuma' />
          <img src={Mirai} alt='Fukuma' />
        </div>

        {!isLoggedIn && (
          <div className='drawer-login-container'>
            <h3>
              Welcome to Shumi Shop <br /> please login to continue
            </h3>
            <Link to='/login' className='drawer-login'>
              <Button type='primary'>Login</Button>
            </Link>
            <Link to='/register' className='drawer-login'>
              <Button>Register</Button>
            </Link>
            <h1>SHUMI</h1>
          </div>
        )}
        {isLoggedIn && (
          <>
            <div className='drawer-container drawer-container-wallet'>
              <h1>SHUMI</h1>
              <div className='drawer-wallet'>
                <h2>Shumi Wallet</h2>
                <p>
                  <img
                    src={SW}
                    alt=''
                    width='38px'
                    style={{ marginTop: '-8px' }}
                  />
                  <NumberFormat
                    displayType='text'
                    value={point}
                    prefix='.'
                    thousandSeparator
                  />
                </p>
              </div>
            </div>
            <div className='drawer-container-menu'>
              <div
                className='sidebar-link'
                onClick={() => this.redirect('/order')}
              >
                <SwapOutlined className='sidebar-icon' />
                <h3>Transaction</h3>
              </div>

              <div
                className='sidebar-link'
                onClick={() => this.redirect('/cart')}
              >
                <Badge count={cart && cart.total_item}>
                  <ShoppingCartOutlined className='sidebar-icon' />
                </Badge>
                <h3>Cart</h3>
              </div>

              <div
                className='sidebar-link'
                onClick={() => this.redirect('/wallet')}
              >
                <WalletOutlined className='sidebar-icon' />
                <h3>Wallet</h3>
              </div>

              <div
                className='sidebar-link'
                onClick={() => this.redirect('/address')}
              >
                <HomeOutlined className='sidebar-icon' />
                <h3>Address</h3>
              </div>
              <div
                className='sidebar-link'
                onClick={() => this.redirect('/wishlist')}
              >
                <HeartOutlined className='sidebar-icon' />
                <h3>Wishlist</h3>
              </div>
              <div
                className='sidebar-link'
                onClick={() => this.redirect('/profile')}
              >
                <UserOutlined className='sidebar-icon' />
                <h3>Profile</h3>
              </div>
              <div
                className='sidebar-link drawer-logout'
                onClick={async () => {
                  const { logout } = this.props;
                  await message.loading('Logout ..');
                  try {
                    logout();
                    message.success('Success Logout');
                  } catch (error) {
                    message.error('Error Logout');
                  }
                }}
              >
                <LogoutOutlined className='sidebar-icon' />
                <h3>Logout</h3>
              </div>
            </div>
          </>
        )}
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart.data.cart,
});

export default withRouter(connect(mapStateToProps, { logout })(Sidebar));
