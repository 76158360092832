import React, { useEffect } from 'react';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getOauthLogin, checkLoggedIn } from '../actions/authAction';
import Loading from '../components/Loading';
import withAnalytics from '../components/withAnalytics';

const OAuthPage = (props) => {
  const email = props.match.params.email;
  const token = props.match.params.token;
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const error = useSelector((state) => state.auth.error);

  const checkLogin = async () => {
    await dispatch(checkLoggedIn());
    if (isLoggedIn) {
      window.location.replace('/');
    }

    if (error != null) {
      await error.map((val) => {
        return notification['error']({
          message: 'Error',
          description: val,
        });
      });
    }
  };

  useEffect(() => {
    (async () => {
      await dispatch(getOauthLogin({ email, token }));
      checkLogin();
    })();
  });

  return <Loading />;
};

export default withAnalytics(OAuthPage);
