import {
  FAILED_LOGIN,
  GET_LOGIN,
  GET_LOGOUT,
  GET_REGISTER,
  RESET_FAILED_LOGIN,
  SUCCESS_LOGIN,
  FAILED_LOGIN_TOKEN,
  SUCCESS_VERIFY,
  FAILED_VERIFY,
  GET_RESET_TOKEN,
  FAILED_GET_RESET_TOKEN,
  GET_RESET_PASSWORD,
  CREATE_RESET_TOKEN,
  ADD_PRIMARY_ADDRESS,
  SUCCESS_UPDATE_GENERAL,
  FAILED_UPDATE_PASSWORD,
  FAILED_UPDATE_EMAIL,
  SUCCESS_UPDATE_EMAIL,
  SUCCESS_VERIFY_UPDATE,
  FAILED_VERIFY_UPDATE,
} from '../constant';

const INITIAL_STATE = {
  isLoggedIn: null,
  token: '',
  user: {},
  error: null,
  justRegister: false,
  isVerify: false,
  isVerifyUpdate: false,
  errorVerifyUpdate: null,
  errorVerify: '',
  errorReset: '',
  errorUpdatePassword: null,
  errorUpdateEmail: null,
  isTokenSent: false,
  isResetSuccess: false,
  resetInfo: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOGIN:
      return { ...state, token: action.payload.token };
    case GET_REGISTER:
      return { ...state, user: action.payload.user, justRegister: true };
    case FAILED_LOGIN:
      return { ...state, error: action.payload, isLoggedIn: false };
    case GET_LOGOUT:
      return { ...state, isLoggedIn: false };
    case RESET_FAILED_LOGIN:
      return { ...state, error: null };
    case FAILED_LOGIN_TOKEN:
      return { ...state, isLoggedIn: false };
    case SUCCESS_LOGIN:
      return { ...state, user: action.payload, isLoggedIn: true };
    case SUCCESS_VERIFY:
      return { ...state, isVerify: true };
    case FAILED_VERIFY:
      return { ...state, isVerify: false, errorVerify: action.payload };
    case CREATE_RESET_TOKEN:
      return { ...state, isTokenSent: true };
    case FAILED_GET_RESET_TOKEN:
      return { ...state, errorReset: action.payload };
    case GET_RESET_TOKEN:
      return { ...state, resetInfo: action.payload };
    case GET_RESET_PASSWORD:
      return { ...state, isResetSuccess: true, errorReset: '' };
    case ADD_PRIMARY_ADDRESS:
      return {
        ...state,
        user: { ...state.user, addressMain: action.payload.id },
      };
    case SUCCESS_UPDATE_GENERAL:
      return {
        ...state,
        user: {
          ...state.user,
          name: action.payload.name,
          gender: action.payload.gender,
          phone: action.payload.phone,
        },
      };
    case SUCCESS_UPDATE_EMAIL:
      return {
        ...state,
        user: { ...state.user, email: action.payload },
      };
    case FAILED_UPDATE_PASSWORD:
      return {
        ...state,
        errorUpdatePassword: action.payload,
      };
    case FAILED_UPDATE_EMAIL:
      return {
        ...state,
        errorUpdateEmail: action.payload,
      };
    case SUCCESS_VERIFY_UPDATE:
      return { ...state, isVerifyUpdate: true };
    case FAILED_VERIFY_UPDATE:
      return { ...state, errorVerifyUpdate: action.payload };
    default:
      return state;
  }
};
